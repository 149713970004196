<template>
  <div class="ds-date-field-range">
    <div class="ds-text-field__label">
      <PfLabel
        :model-value="props.label"
        :required="props.required"
        :disabled="props.disabled"
        @click="openCalendar"
      />
    </div>
    <div
      id="id-date-field"
      class="date-field"
    >
      <button
        ref="dateFieldButtonRef"
        :disabled="props.disabled"
        type="button"
        class="date-field__button"
        :data-test="props.dataTest"
        @click="openCalendar"
      >
        <div class="date-field__button--left">
          <DsIcon
            v-if="!props.hideIcon"
            name="calendar"
            color="gray600"
          />
          <span v-if="!!dateBegin || !!dateEnd">
            {{
              !!dateBegin
                ? $d(dateBegin, {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                  })
                : ''
            }}
            -
            {{
              !!dateEnd
                ? $d(dateEnd, {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                  })
                : ''
            }}
          </span>

          <span
            v-else
            class="input__placeholder"
          >
            {{ props.placeholder }}
          </span>
        </div>
        <div style="display: flex; gap: 8px; align-items: center">
          <DsIcon
            v-if="(dateBegin || dateEnd) && props.clearable && !props.disabled"
            :name="isCalendarOpen ? 'cross' : 'cross'"
            color="gray400"
            size="small"
            @click.stop="clearDates"
          />
          <DsIcon
            v-if="!props.hideChevron"
            :name="isCalendarOpen ? 'chevron-up' : 'chevron-down'"
            :color="isCalendarOpen ? 'blue500' : 'gray600'"
            size="small"
          />
        </div>
      </button>
      <div
        v-show="isCalendarOpen"
        ref="calendarRef"
        :class="{
          'date-field__calendar': true,
          'date-field__calendar--right': props.showRight,
          'date-field__calendar--left': !props.showRight
        }"
      >
        <DsNewMultiCalendar
          v-model:default-date-begin="dateBegin"
          v-model:default-date-end="dateEnd"
          :data-test="`${props.dataTest}-calendar`"
          class="date-field__calendar--range"
          :allow-dates-after-today="!props.disableDatesAfterToday"
          :disables-dates-before-today="props.disableDatesBeforeToday"
          :hide-presets="!props.showPresets"
          :disabled="props.disabled"
          :min-date="props.minDate"
          :max-date="props.maxDate"
          @update:model-value="isCalendarOpen = false"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core';
import { nextTick, onMounted, ref } from 'vue';

import PfLabel from '../NewDesignSystem/PfLabel/PfLabel.vue';
import DsIcon from './DsIcon.vue';
import DsNewMultiCalendar from './DsNewMultiCalendar.vue';

const props = defineProps<{
  disabled?: boolean;
  label?: string;
  required?: boolean;
  placeholder?: string;
  disableDatesAfterToday?: boolean;
  disableDatesBeforeToday?: boolean;
  showPresets?: boolean;
  hideIcon?: boolean;
  hideChevron?: boolean;
  defaultDateBegin?: Date;
  defaultDateEnd?: Date;
  scrollIntoView?: boolean;
  showRight?: boolean;
  clearable?: boolean;
  dataTest?: string;
  minDate?: Date;
  maxDate?: Date;
}>();

const dateBegin = defineModel<Date>('dateBegin');
const dateEnd = defineModel<Date>('dateEnd');

const emit = defineEmits<{
  (e: 'onCloseCalendar'): void;
}>();

const dateFieldButtonRef = ref(null);
const isCalendarOpen = ref(false);

async function openCalendar() {
  await nextTick();
  dateFieldButtonRef.value?.focus();
  isCalendarOpen.value = true;
  if (props.scrollIntoView) {
    await nextTick();
    calendarRef.value?.scrollIntoView();
  }
}

function clearDates() {
  dateBegin.value = undefined;
  dateEnd.value = undefined;
}

const calendarRef = ref<HTMLElement>();

onClickOutside(calendarRef, () => {
  if (!isCalendarOpen.value) {
    return;
  }

  isCalendarOpen.value = false;
  emit('onCloseCalendar');
});

onMounted(() => {
  if (props.defaultDateBegin) {
    dateBegin.value = props.defaultDateBegin;
  }
  if (props.defaultDateEnd) {
    dateEnd.value = props.defaultDateEnd;
  }
});

defineExpose({
  openCalendar
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/design-system/field-label.scss';

.ds-date-field-range {
  min-width: 0;
}

.date-field {
  position: relative;

  .date-field__button {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 36px;
    padding: 0 12px;

    background-color: white;
    border: 1px solid $gray100;
    border-radius: 12px;

    .date-field__button--left {
      overflow: hidden;
      display: flex;
      gap: 8px;
      align-items: center;

      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;

      > span {
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &:hover {
      border-color: $gray200;
    }

    &:focus {
      border-color: $blue500;
      outline: 2px solid $blue150;
    }

    &:disabled {
      cursor: not-allowed;
      color: $gray500;
      background-color: $gray50;
    }
  }

  .date-field__calendar {
    position: absolute;
    z-index: 99;

    background-color: white;
    border-radius: 12px;
    box-shadow: 0 16px 24px 0 #14172529;
  }

  .date-field__calendar--range {
    border-radius: 12px;
  }

  .date-field__calendar--single {
    > div {
      width: 260px;
    }
  }
}

.label-asterisk {
  color: $red500;
}

.input__placeholder {
  @include typo-body;

  overflow: hidden;
  color: $gray400;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.date-field__calendar--right {
  right: 0;
  left: auto;
}

.date-field__calendar--left {
  right: auto;
  left: 0;
}

.ds-text-field__label {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
}
</style>
