<template>
  <div
    :class="{
      'ds-text-field': true,
      'ds-text-field--is-disabled': disabled
    }"
  >
    <div class="ds-text-field__label">
      <PfLabel
        :model-value="label"
        :required="required"
        :disabled="disabled"
        @click.stop="focus"
      />
      <slot
        v-if="$slots.rightLabel"
        name="rightLabel"
      />
    </div>
    <DsSkeleton
      v-if="loading"
      class="field-input field-input--loading"
    />
    <input
      v-else
      ref="modelInput"
      v-model="inputValue"
      :name="name"
      :type="type"
      class="field-input"
      :class="{ error: hasError, warning: hasWarning, icon: hasIcon }"
      :placeholder="placeholder"
      :disabled="disabled"
      :required="required"
      :pattern="pattern"
      :min="min"
      :maxlength="maxlength"
      :step="step"
      :lang="$i18n.locale"
      :title="title"
      :data-test="dataTest || name"
      @input="onInput"
      @keyup.stop="$emit('keyup', $event)"
      @keydown.stop="$emit('keydown', $event)"
      @blur="onBlur"
      @focus="$emit('focus')"
    />
    <div
      v-if="hasIcon"
      class="ds-text-field-absolute-left-elements"
    >
      <slot></slot>
    </div>

    <div
      v-if="unit.length || (clearable && inputValue !== '' && !disabled)"
      class="ds-text-field-absolute-right-elements"
    >
      <span
        v-if="unit.length"
        class="ds-text-field-unit"
      >
        {{ unit }}
      </span>

      <DsIconButton
        v-if="clearable && inputValue !== '' && !disabled"
        name="cross"
        size="small"
        color="gray400"
        :class="{
          'ds-text-field-button-clear': true,
          'has-label': Boolean(label)
        }"
        @click="onClear"
      />
    </div>

    <p
      v-if="hasError && errorMessage?.length > 0"
      class="ds-text-field-message error"
    >
      {{ errorMessage }}
    </p>

    <p
      v-if="message.length"
      class="ds-text-field-message"
    >
      {{ message }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { nextTick, onMounted, ref } from 'vue';

import PfLabel from '../NewDesignSystem/PfLabel/PfLabel.vue';
import DsIconButton from './DsIconButton.vue';
import DsSkeleton from './DsSkeleton.vue';

const inputValue = defineModel<string | number>();

const props = defineProps({
  name: { type: String, default: '' },
  label: { type: String, default: '' },
  type: { type: String, default: 'text' },
  placeholder: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  autofocus: { type: Boolean, default: false },
  clearable: { type: Boolean, default: false },
  hasError: { type: Boolean, default: false },
  hasWarning: { type: Boolean, default: false },
  errorMessage: { type: String, default: '' },
  title: { type: String, default: undefined },
  dataTest: { type: String, default: '' },
  message: { type: String, default: '' },
  unit: { type: String, default: '' },
  hasIcon: { type: Boolean, default: false },
  preventTrim: { type: Boolean, default: false },
  pattern: { type: String, default: undefined },
  min: { type: Number, default: undefined },
  maxlength: { type: Number, default: undefined },
  step: { type: String, default: undefined },
  loading: { type: Boolean, default: false }
});

const emit = defineEmits(['keyup', 'keydown', 'blur', 'cleared', 'focus']);

const modelInput = ref<HTMLInputElement>();

const onInput = (event) => {
  if (props.type === 'number') {
    inputValue.value = event.target.value.length
      ? Number.parseFloat(event.target.value)
      : 0;
  }
};

const onBlur = (event: Event) => {
  if (props.type === 'text' && !props.preventTrim && inputValue.value) {
    inputValue.value = String(inputValue.value).trim();
  }
  emit('blur', event);
};

const onClear = () => {
  inputValue.value = '';
  focus();
  emit('cleared');
};

const focus = () => {
  if (modelInput.value) {
    modelInput.value.focus();
  }
};

onMounted(() => {
  if (props.autofocus) {
    nextTick(focus);
  }
});

defineExpose({ focus });
</script>

<style lang="scss" scoped>
@import '../../assets/scss/design-system/field-label.scss';
@import '../../assets/scss/design-system/text-field.scss';

.field-input--loading {
  pointer-events: none;
  margin: 0;
}

.ds-text-field__label {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
}
</style>
