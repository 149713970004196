import { useAnalyticalAxesStore } from '@/stores/data-models/analytical-axes';

import { Role } from './policies';

export type ClientId = number;
export interface ClientModel {
  entity_id: number;
  analyticalAxis: { COST_CENTER: number; TEAM: number };
  entity: any;
  id: ClientId;
  tenant_id: number;
  role?: Role;
  email: string;
  phone?: string;
  birth_date?: string;
  country_code?: string;
  first_name?: string;
  last_name?: string;
  job_title?: string;
  default_currency?: string;
  preferred_language?: string;
  status: 'INVITED' | 'REGISTERED';
  updated_at?: string;
  updated_by?: ClientId;
  entity_ids?: number[];
  slack_active?: boolean;
  email_active: boolean;
  erp_subsidiary_ids?: number[];
}

export interface ClientInformations {
  role: Role;
  email: string;
  phone?: string;
  country_code?: string;
  first_name?: string;
  last_name?: string;
  job_title?: string;
  default_currency?: string;
  preferred_language?: string;
}

// Roles pour les différentes pages
export type WorkspaceScope = 'SETTINGS' | 'ANALYTICS' | 'PAYMENTS' | 'REQUESTS';

export type SettingsRole = 'OWNER' | 'ADMIN' | 'USER';
export type AnalyticsRole = 'VIEWER' | 'CONTRIBUTOR';

export enum RequestRole {
  NO_ACCESS = 'NO_ACCESS',
  REQUESTOR = 'REQUESTOR',
  CONTROLLER = 'CONTROLLER',
  APPROVER = 'APPROVER',
  SUPER_APPROVER = 'SUPER_APPROVER',
  UNRESTRICTED = 'UNRESTRICTED'
}

export enum PaymentsRole {
  NO_ACCESS = 'NO_ACCESS',
  REQUESTOR = 'REQUESTOR',
  CONTROLLER = 'CONTROLLER',
  APPROVER = 'APPROVER',
  SUPER_APPROVER = 'SUPER_APPROVER',
  UNRESTRICTED = 'UNRESTRICTED',

  PAYER = 'PAYER',
  PAYER_ALL_ACCESS = 'PAYER_ALL_ACCESS'
}

export enum NotificationChannels {
  'SLACK' = 'SLACK',
  'EMAIL' = 'EMAIL'
}

export interface RetrieveRoles {
  settings_role: SettingsRole;
  analytics_role?: AnalyticsRole;
  requests_roles?: RequestRole[];
  payments_roles?: PaymentsRole[];
}

export interface RetrieveUserRoleOutput {
  user_id: ClientId;
  roles: RetrieveRoles;
}

export interface UserPermissionsRow {
  user: ClientModel;
  workspaceScopes: {
    SETTINGS: SettingsRole;
    ANALYTICS?: AnalyticsRole;
    PAYMENTS?: PaymentsRole[];
    REQUESTS?: RequestRole[];
  };
}

export type ClientWithRoleModel = UserPermissionsRow;

// TEMPORARY HACK on Requests field, deduced with Payments field
export const clientRoleDataToUserPermissionsRow = (
  clientRoleData: RetrieveRoles,
  clientModel: ClientModel
): UserPermissionsRow => {
  return {
    user: clientModel,
    workspaceScopes: {
      SETTINGS: clientRoleData.settings_role,
      ANALYTICS: clientRoleData.analytics_role,
      PAYMENTS: extractPaymentRoles(clientRoleData),
      REQUESTS: clientRoleData.payments_roles
        ? (clientRoleData.payments_roles?.filter(
            (elt) =>
              ![PaymentsRole.PAYER, PaymentsRole.PAYER_ALL_ACCESS].includes(elt)
          ) as any)
        : []
    }
  };
};

const extractPaymentRoles = (clientRoleData) => {
  if (clientRoleData.payments_roles?.includes(PaymentsRole.PAYER_ALL_ACCESS)) {
    return [PaymentsRole.PAYER, PaymentsRole.PAYER_ALL_ACCESS];
  }

  if (clientRoleData.payments_roles?.includes(PaymentsRole.PAYER)) {
    return [PaymentsRole.PAYER];
  }

  return [];
};

// takes a list of RetrieveUserRoleOutput and a list of ClientModel and returns a list of UserPermissionsRow, with a UserPermissionsRow for each ClientModel regardless they have a corresponding RetrieveUserRoleOutput or not
export const clientRoleDataToUserPermissionsRows = (
  clientRoleDataList: RetrieveUserRoleOutput[],
  clients: ClientModel[],
  clientsNodesPermissions: any = {}
): UserPermissionsRow[] => {
  const analyticalAxesStore = useAnalyticalAxesStore();
  const clientRoleDataDictionary = clientRoleDataList.reduce(
    (acc, clientRoleData) => {
      acc[clientRoleData.user_id] = clientRoleData;
      return acc;
    },
    {} as { [userId: number]: RetrieveUserRoleOutput }
  );

  const idCostCenter = analyticalAxesStore.analyticalAxesArray.find(
    (elt) => elt.axis_type === 'COST_CENTER'
  )?.id;
  const idTeam = analyticalAxesStore.analyticalAxesArray.find(
    (elt) => elt.axis_type === 'TEAM'
  )?.id;

  return clients.map((client) => {
    const clientRoleData =
      clientRoleDataDictionary[client.id] ||
      ({
        user_id: client.id,
        roles: {
          settings_role: 'USER'
        }
      } as RetrieveUserRoleOutput);
    if (
      !!clientsNodesPermissions[client.id] &&
      !!clientsNodesPermissions[client.id].length &&
      (!!idCostCenter || !!idTeam)
    ) {
      client.analyticalAxis = {
        COST_CENTER: clientsNodesPermissions[client.id].find(
          (elt) => elt.analytical_axis_id === idCostCenter
        )?.analytical_axis_node_id,
        TEAM: clientsNodesPermissions[client.id].find(
          (elt) => elt.analytical_axis_id === idTeam
        )?.analytical_axis_node_id
      };
    } else {
      client.analyticalAxis = {
        COST_CENTER: null,
        TEAM: null
      };
    }

    return clientRoleDataToUserPermissionsRow(clientRoleData.roles, client);
  });
};

if (import.meta.vitest) {
  const { it, expect, describe } = import.meta.vitest;

  describe('clientRoleDataToUserPermissionsRow', () => {
    it('clientRoleDataToUserPermissionsRow works as expected', () => {
      const user = {
        id: 12,
        first_name: 'Quentin',
        last_name: 'Morisseau',
        email: 'quentin@payflows.io',
        updated_by: 13,
        tenant_id: 1,
        role: Role.VIEWER,
        status: 'REGISTERED'
      } as ClientModel;

      const roles1 = {
        settings_role: 'USER',
        analytics_role: 'CONTRIBUTOR',
        payments_roles: [PaymentsRole.PAYER],
        requests_roles: [RequestRole.REQUESTOR]
      } as RetrieveRoles;

      const roles2 = {
        settings_role: 'USER',
        payments_roles: [PaymentsRole.PAYER]
      } as RetrieveRoles;

      const roles3 = {
        settings_role: 'USER',
        analytics_role: 'CONTRIBUTOR'
      } as RetrieveRoles;

      const roles4 = {
        settings_role: 'USER'
      } as RetrieveRoles;

      const expectedResult1 = {
        user: {
          id: 12,
          first_name: 'Quentin',
          last_name: 'Morisseau',
          email: 'quentin@payflows.io',
          updated_by: 13,
          tenant_id: 1,
          role: Role.VIEWER,
          status: 'REGISTERED'
        },
        workspaceScopes: {
          SETTINGS: 'USER',
          ANALYTICS: 'CONTRIBUTOR',
          PAYMENTS: [PaymentsRole.PAYER],
          REQUESTS: [RequestRole.REQUESTOR]
        }
      } as UserPermissionsRow;

      const expectedResult2 = {
        user: {
          id: 12,
          first_name: 'Quentin',
          last_name: 'Morisseau',
          email: 'quentin@payflows.io',
          updated_by: 13,
          tenant_id: 1,
          role: Role.VIEWER,
          status: 'REGISTERED'
        },
        workspaceScopes: {
          SETTINGS: 'USER',
          PAYMENTS: [PaymentsRole.PAYER]
        }
      } as UserPermissionsRow;

      const expectedResult3 = {
        user: {
          id: 12,
          first_name: 'Quentin',
          last_name: 'Morisseau',
          email: 'quentin@payflows.io',
          updated_by: 13,
          tenant_id: 1,
          role: Role.VIEWER,
          status: 'REGISTERED'
        },
        workspaceScopes: {
          SETTINGS: 'USER',
          ANALYTICS: 'CONTRIBUTOR'
        }
      } as UserPermissionsRow;

      const expectedResult4 = {
        user: {
          id: 12,
          first_name: 'Quentin',
          last_name: 'Morisseau',
          email: 'quentin@payflows.io',
          updated_by: 13,
          tenant_id: 1,
          role: Role.VIEWER,
          status: 'REGISTERED'
        },
        workspaceScopes: {
          SETTINGS: 'USER'
        }
      } as UserPermissionsRow;

      expect(clientRoleDataToUserPermissionsRow(roles1, user)).toEqual(
        expectedResult1
      );
      expect(clientRoleDataToUserPermissionsRow(roles2, user)).toEqual(
        expectedResult2
      );
      expect(clientRoleDataToUserPermissionsRow(roles3, user)).toEqual(
        expectedResult3
      );
      expect(clientRoleDataToUserPermissionsRow(roles4, user)).toEqual(
        expectedResult4
      );
    });
  });

  describe('clientRoleDataToUserPermissionsRows', () => {
    const user1 = {
      id: 1,
      tenant_id: 1,
      role: Role.VIEWER,
      email: 'john.doe@example.com',
      status: 'INVITED',
      email_active: true
    } as ClientModel;

    const user2 = {
      id: 2,
      tenant_id: 1,
      role: Role.ADMIN,
      email: 'jane.doe@example.com',
      phone: '+1 123-456-7890',
      birth_date: '1990-01-01',
      country_code: 'US',
      first_name: 'Jane',
      last_name: 'Doe',
      job_title: 'Software Engineer',
      default_currency: 'USD',
      preferred_language: 'en',
      status: 'REGISTERED',
      email_active: true
    } as ClientModel;

    const user3 = {
      id: 3,
      tenant_id: 1,
      role: Role.ADMIN,
      email: 'james.does@veryverylongdomainname.com',
      phone: '+1 123-456-7890',
      birth_date: '1990-01-01',
      country_code: 'US',
      first_name: 'James',
      last_name: 'Does',
      job_title: 'Software Engineer',
      default_currency: 'USD',
      preferred_language: 'en',
      status: 'INVITED',
      email_active: true
    } as ClientModel;

    const clients = [user1, user2, user3];

    it('works as expect when every ClientModel has a corresponding RetrieveUserRoleOutput', () => {
      const roles1 = {
        user_id: 1,
        roles: {
          settings_role: 'USER',
          analytics_role: 'CONTRIBUTOR',
          payments_roles: [
            PaymentsRole.REQUESTOR,
            PaymentsRole.APPROVER,
            PaymentsRole.PAYER
          ]
        }
      } as RetrieveUserRoleOutput;

      const roles2 = {
        user_id: 2,
        roles: {
          settings_role: 'USER',
          payments_roles: [
            PaymentsRole.REQUESTOR,
            PaymentsRole.APPROVER,
            PaymentsRole.PAYER
          ]
        }
      } as RetrieveUserRoleOutput;

      const roles3 = {
        user_id: 3,
        roles: {
          settings_role: 'USER',
          analytics_role: 'CONTRIBUTOR'
        }
      } as RetrieveUserRoleOutput;

      const rolesList1 = [roles3, roles1, roles2];

      const expectedResult1 = [
        {
          user: user1,
          workspaceScopes: {
            SETTINGS: 'USER',
            ANALYTICS: 'CONTRIBUTOR',
            PAYMENTS: [
              PaymentsRole.REQUESTOR,
              PaymentsRole.APPROVER,
              PaymentsRole.PAYER
            ]
          }
        },
        {
          user: user2,
          workspaceScopes: {
            SETTINGS: 'USER',
            PAYMENTS: [
              PaymentsRole.REQUESTOR,
              PaymentsRole.APPROVER,
              PaymentsRole.PAYER
            ]
          }
        },
        {
          user: user3,
          workspaceScopes: {
            SETTINGS: 'USER',
            ANALYTICS: 'CONTRIBUTOR'
          }
        }
      ];

      expect(clientRoleDataToUserPermissionsRows(rolesList1, clients)).toEqual(
        expectedResult1
      );
    });

    it("works as expected when some ClientModel don't have a corresponding RetrieveUserRoleOutput", () => {
      const roles3 = {
        user_id: 3,
        roles: {
          settings_role: 'ADMIN',
          analytics_role: 'CONTRIBUTOR',
          payments_roles: [
            PaymentsRole.REQUESTOR,
            PaymentsRole.APPROVER,
            PaymentsRole.PAYER
          ]
        }
      } as RetrieveUserRoleOutput;

      const rolesList2 = [roles3];

      const expectedResult2 = [
        {
          user: user1,
          workspaceScopes: {
            SETTINGS: 'USER'
          }
        },
        {
          user: user2,
          workspaceScopes: {
            SETTINGS: 'USER'
          }
        },
        {
          user: user3,
          workspaceScopes: {
            SETTINGS: 'ADMIN',
            ANALYTICS: 'CONTRIBUTOR',
            PAYMENTS: [
              PaymentsRole.REQUESTOR,
              PaymentsRole.APPROVER,
              PaymentsRole.PAYER
            ]
          }
        }
      ];

      expect(clientRoleDataToUserPermissionsRows(rolesList2, clients)).toEqual(
        expectedResult2
      );
    });
  });
}
