import { ref, watch } from 'vue';

import { useToastStore } from '@/stores/toast';

export const useLazyLoad = (
  promises: (() => Promise<any>)[],
  cacheTtl = 1000 * 60
) => {
  const toast = useToastStore();

  const isPending = ref(false);
  const lastCallTimestamp = ref<Date>();

  const waitForCurrentRequest = () =>
    new Promise((resolve) => {
      const unwatch = watch(
        isPending,
        (newVal) => {
          if (!newVal) {
            unwatch();
            resolve(true);
          }
        },
        { immediate: true }
      );
    });

  const lazyLoad = async (forceReload = false) => {
    if (isPending.value) {
      await waitForCurrentRequest();
      return;
    }

    const isCacheOutdated =
      !lastCallTimestamp.value ||
      new Date().getTime() - lastCallTimestamp.value?.getTime() > cacheTtl;

    if (forceReload || isCacheOutdated) {
      try {
        isPending.value = true;

        await Promise.all(promises.map((promise) => promise()));
        lastCallTimestamp.value = new Date();
      } catch (error) {
        toast.showError(error);
      } finally {
        isPending.value = false;
      }
    }
  };

  return {
    isPending,
    lazyLoad,
    lastCallTimestamp
  };
};
