import { RouteRecordRaw } from 'vue-router';

import devRoutes from './devRoutes';

export enum RouteNames {
  SIGNUP = 'Signup',
  WITH_GOOGLE = 'WithGoogle',
  WITH_OKTA = 'WithOkta',
  WITH_PING = 'WithPing',
  LOGIN = 'Login',
  FORGOT_PASSWORD = 'ForgotPassword',
  RESET_PASSWORD = 'ResetPassword',

  VENDOR_ONBOARDING = 'VendorOnboarding',
  VENDOR_ONBOARDING_V2 = 'VendorOnboardingV2',

  REDIRECT_TO_MAIN = 'RedirectToMain',

  HOME = 'Home',
  BUDGET = 'Budget',
  INVOICES = 'Invoices',
  INVOICES_SINGLE = 'Invoice',
  PAYMENT_REQUESTS = 'PaymentRequests',
  PAYMENT_REQUESTS_SINGLE = 'PaymentRequest',
  TRANSACTIONS = 'Transactions',
  TRANSACTIONS_IMPORT_PENDING = 'TransactionsImportPending',
  CASHFLOWS = 'Cashflows',
  CASHFLOWS_EDIT_VIEWS = 'CashflowsEditViews',
  CASHFLOWS_PREVISIONS = 'CashflowsPrevisions',
  FORM_REQUESTER = 'FormRequester',
  ACCOUNTING_REVIEW = 'AccountingReview',
  PURCHASE_ORDERS = 'PurchaseOrders',
  PURCHASE_ORDERS_ITEMS = 'LineItemsPurchaseOrders',
  PURCHASE_ORDERS_PERIODS = 'PurchaseOrdersPeriods',
  PURCHASE_ORDERS_RECEPTIONS = 'Receptions',
  PURCHASE_ORDERS_ADD_RECEPTION = 'AddReception',
  PURCHASE_ORDERS_SINGLE = 'PurchaseOrder',
  CONTRACTS = 'Contracts',
  BANK_ORDERS = 'BankOrders',
  BANK_ORDERS_DUPLICATE = 'Duplicate',
  BANK_ORDERS_ITEMS = 'BankOrderItems',
  CATEGORIZATION_WORKFLOWS_NEW_RULE = 'CategorizationWorkflowsNewRule',
  CATEGORIZATION_COUNTERPARTIES_NEW_RULE = 'CategorizationCounterpartiesNewRule',
  CATEGORIZATION_WORKFLOWS_EDIT_RULE = 'CategorizationWorkflowsEditRule',
  CATEGORIZATION_COUNTERPARTIES_EDIT_RULE = 'CategorizationCounterpartiesEditRule',
  COUNTERPARTIES_MANAGEMENT = 'CounterpartiesManagement',
  COUNTERPARTIES_SINGLE = 'CounterpartiesSingle',
  VENDORS = 'Vendors',
  VENDORS_SINGLE = 'VendorsSingle',
  VENDORS_REQUEST = 'VendorsRequest',
  MY_PROFILE = 'MyProfile',
  CONNECTOR_CREDS = 'ConnectorCreds',

  SETTINGS = 'Settings',
  SETTINGS_INSTITUTIONS = 'Institutions',
  SETTINGS_ACCOUNTS = 'Accounts',
  SETTINGS_ADD_ACCOUNT = 'AddAccount',
  SETTINGS_ENTITIES = 'Entities',
  SETTINGS_ENTITIES_NEW = 'EntitiesNew',
  SETTINGS_EBICS = 'Ebics',
  SETTINGS_STATEMENTS = 'Statements',
  SETTINGS_ACCOUNT_GROUPS = 'AccountGroups',
  SETTINGS_USERS_MANAGEMENT = 'UsersManagement',
  SETTINGS_DELEGATIONS = 'Delegations',
  SETTINGS_USER_PERMISSIONS = 'UserPermissions',
  SETTINGS_AUTOMATIC_CATEGORIZATIONS = 'AutomaticCategorizations',
  SETTINGS_ANALYTICAL_AXES = 'AnalyticalAxes',
  SETTINGS_ERP_MAPPING = 'ERPMapping',
  SETTINGS_ITEMS_MAPPING = 'ItemsMapping',
  SETTINGS_PROVIDER_MAPPING = 'ProviderMapping',
  SETTINGS_NOTIFICATIONS = 'SettingsNotifications',
  SETTINGS_FORMS = 'Forms',
  SETTINGS_FORM_BUILDER = 'FormBuilder',
  SETTINGS_FORM_BUILDER_VENDOR = 'FormBuilderVendor',
  SETTINGS_ROUTER_BUILDER = 'RouterBuilder',
  SETTINGS_ROUTER_BUILDER_DEV = 'RouterBuilderDev',
  SETTINGS_VIRTUAL_CARDS = 'VirtualCards',
  SETTINGS_VIRTUAL_CARDS_USERS = 'VirtualCardsUsers',
  SETTINGS_PAYMENT_FILE_PRESETS = 'PaymentFilePresets',
  SETTINGS_NEW_PAYMENT_FILE_PRESETS = 'NewPaymentFilePresets',
  SETTINGS_EDIT_PAYMENT_FILE_PRESETS = 'EditPaymentFilePresets',
  SETTINGS_WORKSPACE = 'Workspace',

  SETTINGS_CATALOG = 'Catalog',
  RESOLVE_AUTH_SWAN = 'ResolveAuthSwan',
  EXPECTED_INVOICES_SINGLE = 'EXPECTED_INVOICES_SINGLE',
  PURCHASE_ORDERS_RECEPTIONS_SINGLE = 'PURCHASE_ORDERS_RECEPTIONS_SINGLE',
  TREASURY_DASHBOARD = 'TREASURY_DASHBOARD'
}

export const notAuthenticatedRouteNames: string[] = [
  RouteNames.LOGIN,
  RouteNames.SIGNUP,
  RouteNames.FORGOT_PASSWORD,
  RouteNames.RESET_PASSWORD,
  RouteNames.VENDOR_ONBOARDING,
  RouteNames.VENDOR_ONBOARDING_V2,
  RouteNames.RESOLVE_AUTH_SWAN,
  RouteNames.WITH_OKTA,
  RouteNames.WITH_PING
];

const redirectRoutes: RouteRecordRaw[] = [
  {
    path: '/payment-requests/forms',
    redirect: { name: RouteNames.PAYMENT_REQUESTS }
  },
  // TODO : ask backend devs to change the url to /payment-requests in notifications
  {
    path: '/payment-requests-po',
    redirect: { name: RouteNames.PAYMENT_REQUESTS }
  },
  {
    path: '/settings/my-profile',
    redirect: { name: RouteNames.MY_PROFILE }
  },
  {
    path: '/settings/intake-forms',
    redirect: { name: RouteNames.SETTINGS_FORMS }
  },
  {
    path: '/settings/workflows',
    redirect: { name: RouteNames.SETTINGS_FORMS }
  },
  {
    path: '/settings/workflows/edit',
    redirect: { name: RouteNames.SETTINGS_FORM_BUILDER }
  },
  { path: '/welcome', redirect: { name: RouteNames.HOME } }
];

const routes: RouteRecordRaw[] = [
  {
    path: '/signup',
    name: RouteNames.SIGNUP,
    component: () => import('@/features/Auth/Signup/Signup.vue')
  },
  {
    path: '/with-google',
    name: RouteNames.WITH_GOOGLE,
    component: () => import('@/views/redirect/WithGoogle.vue')
  },
  {
    path: '/with-okta',
    name: RouteNames.WITH_OKTA,
    component: () => import('@/features/Auth/Login/OktaPageLoginReplicate.vue')
  },
  {
    path: '/with-ping',
    name: RouteNames.WITH_PING,
    component: () => import('@/features/Auth/Login/PingLoginPage.vue')
  },
  {
    path: '/login',
    name: RouteNames.LOGIN,
    component: () => import('@/features/Auth/Login/Login.vue')
  },
  {
    path: '/forgot-password',
    name: RouteNames.FORGOT_PASSWORD,
    component: () => import('@/features/Auth/ForgotPassword/ForgotPassword.vue')
  },
  {
    path: '/reset-password',
    name: RouteNames.RESET_PASSWORD,
    component: () => import('@/views/ResetPassword.vue')
  },
  {
    path: '/vendor-onboarding',
    name: RouteNames.VENDOR_ONBOARDING,
    component: () => import('@/features/VendorOnboarding/VendorOnboarding.vue')
  },
  {
    path: '/vendor-onboarding-v2',
    name: RouteNames.VENDOR_ONBOARDING_V2,
    component: () =>
      import('@/features/VendorOnboardingV2/VendorOnboarding.vue')
  },
  {
    path: '/dashboard',
    component: () => import('@/views/dashboard/index.vue'),
    children: [
      ...(import.meta.env.VITE_IS_MAIN === 'true' ? [] : devRoutes),
      {
        path: '/',
        name: RouteNames.REDIRECT_TO_MAIN,
        component: () => import('@/views/dashboard/RedirectStub.vue')
      },
      {
        path: '/home',
        name: RouteNames.HOME,
        component: () => import('@/features/Dashboard/Dashboard.vue')
      },
      {
        path: '/treasury-dashboard',
        name: RouteNames.TREASURY_DASHBOARD,
        component: () => import('@/features/Home/Home.vue')
      },
      {
        path: '/budget',
        name: RouteNames.BUDGET,
        component: () => import('@/views/dashboard/Budget.vue')
      },
      {
        path: '/invoices',
        children: [
          {
            path: '',
            name: RouteNames.INVOICES,
            component: () => import('@/features/Invoices/Invoices.vue')
          },
          {
            path: ':id',
            name: RouteNames.INVOICES_SINGLE,
            component: () =>
              import('@/features/Invoices/Single/InvoicesSingleView.vue')
          },
          {
            path: 'accounting-review',
            name: RouteNames.ACCOUNTING_REVIEW,
            component: () =>
              import(
                '@/features/Invoices/AccountingReview/AccountingReviewView.vue'
              )
          },
          {
            path: 'expected/:id',
            name: RouteNames.EXPECTED_INVOICES_SINGLE,
            component: () =>
              import(
                '@/components/Intake/ExpectedInvoices/Single/ExpectedInvoicesSingleView.vue'
              )
          }
        ]
      },
      {
        path: '/payment-requests',
        children: [
          {
            path: '',
            name: RouteNames.PAYMENT_REQUESTS,
            component: () =>
              import('@/features/PaymentRequests/PaymentRequests.vue')
          },
          {
            path: ':id',
            name: RouteNames.PAYMENT_REQUESTS_SINGLE,
            component: () =>
              import(
                '@/features/PaymentRequests/Single/PaymentRequestsSingleView.vue'
              )
          }
        ]
      },
      {
        path: '/transactions',
        children: [
          {
            path: '',
            name: RouteNames.TRANSACTIONS,
            component: () => import('@/features/Transactions/Transactions.vue')
          },
          {
            path: 'import-pending',
            name: RouteNames.TRANSACTIONS_IMPORT_PENDING,
            component: () =>
              import('@/views/dashboard/TransactionsImportPending.vue')
          }
        ]
      },
      {
        path: '/cashflows',
        children: [
          {
            path: '',
            name: RouteNames.CASHFLOWS,
            component: () => import('@/features/Cashflows/View/Cashflows.vue')
          },
          {
            path: 'edit',
            name: RouteNames.CASHFLOWS_EDIT_VIEWS,
            component: () =>
              import('@/features/Cashflows/EditViews/CashflowsEditViews.vue')
          },
          {
            path: 'previsions',
            name: RouteNames.CASHFLOWS_PREVISIONS,
            component: () =>
              import('@/features/Cashflows/Previsions/CashflowsPrevisions.vue')
          }
        ]
      },
      {
        path: '/form-requester',
        name: RouteNames.FORM_REQUESTER,
        component: () => import('@/views/dashboard/FormRequester.vue')
      },
      {
        path: '/purchase-orders',
        children: [
          {
            path: '',
            name: RouteNames.PURCHASE_ORDERS,
            component: () =>
              import(
                '@/features/PurchaseOrders/List/PurchaseOrdersListView.vue'
              )
          },
          {
            path: ':purchaseOrderId/items',
            name: RouteNames.PURCHASE_ORDERS_ITEMS,
            component: () =>
              import(
                '@/features/PurchaseOrders/LineItems/PurchaseOrdersLineItemsView.vue'
              )
          },
          {
            path: ':purchaseOrderId/periods',
            name: RouteNames.PURCHASE_ORDERS_PERIODS,
            component: () =>
              import(
                '@/features/PurchaseOrders/Periods/PurchaseOrdersPeriodsView.vue'
              )
          },
          {
            path: ':purchaseOrderId/receptions',
            name: RouteNames.PURCHASE_ORDERS_RECEPTIONS,
            component: () =>
              import(
                '@/features/PurchaseOrders/ReceptionsList/PurchaseOrdersReceptionsListView.vue'
              )
          },
          {
            path: ':purchaseOrderId/receptions/:receptionId',
            name: RouteNames.PURCHASE_ORDERS_RECEPTIONS_SINGLE,
            component: () =>
              import(
                '@/features/PurchaseOrders/PurchaseOrdersReception/PurchaseOrdersReceptionView.vue'
              )
          },
          {
            path: ':purchaseOrderId/receptions/add',
            name: RouteNames.PURCHASE_ORDERS_ADD_RECEPTION,
            component: () =>
              import(
                '@/features/PurchaseOrders/ReceptionsAdd/PurchaseOrdersReceptionsAddView.vue'
              )
          },
          {
            path: ':id',
            name: RouteNames.PURCHASE_ORDERS_SINGLE,
            component: () =>
              import(
                '@/features/PurchaseOrders/Single/PurchaseOrdersSingleView.vue'
              )
          }
        ]
      },
      {
        path: '/contracts',
        name: RouteNames.CONTRACTS,
        component: () => import('@/views/dashboard/Contracts.vue')
      },
      {
        path: '/bank-orders',
        children: [
          {
            path: '',
            name: RouteNames.BANK_ORDERS,
            component: () => import('@/features/BankOrders/BankOrders.vue')
          },
          {
            path: 'duplicate/:id',
            name: RouteNames.BANK_ORDERS_DUPLICATE,
            component: () =>
              import('@/features/BankOrders/BankOrdersDuplicate.vue')
          },
          {
            path: ':bankOrderId/items',
            name: RouteNames.BANK_ORDERS_ITEMS,
            component: () =>
              import('@/features/BankOrderItems/BankOrderItems.vue')
          }
        ]
      },
      {
        path: '/categorization-workflows/new',
        name: RouteNames.CATEGORIZATION_WORKFLOWS_NEW_RULE,
        component: () =>
          import('@/views/dashboard/CategorizationWorkflowsNew.vue')
      },
      {
        path: '/categorization-counterparties/new',
        name: RouteNames.CATEGORIZATION_COUNTERPARTIES_NEW_RULE,
        component: () =>
          import('@/views/dashboard/CategorizationCounterpartiesNewRule.vue')
      },
      {
        path: '/categorization-workflows/edit',
        name: RouteNames.CATEGORIZATION_WORKFLOWS_EDIT_RULE,
        component: () =>
          import('@/views/dashboard/CategorizationWorkflowsEdit.vue')
      },
      {
        path: '/categorization-counterparties/edit',
        name: RouteNames.CATEGORIZATION_COUNTERPARTIES_EDIT_RULE,
        component: () =>
          import(
            '@/views/dashboard/CategorizationWorkflowsCounterpartiesEdit.vue'
          )
      },
      {
        path: '/counterparties-management',
        children: [
          {
            path: '',
            name: RouteNames.COUNTERPARTIES_MANAGEMENT,
            component: () =>
              import('@/views/dashboard/CounterpartiesManagement.vue')
          },
          {
            path: ':id',
            name: RouteNames.COUNTERPARTIES_SINGLE,
            component: () =>
              import('@/features/Vendors/SingleView/VendorSingle.vue')
          }
        ]
      },
      {
        path: '/vendors',
        children: [
          {
            path: '',
            name: RouteNames.VENDORS,
            component: () => import('@/views/dashboard/Vendors.vue')
          },
          {
            path: ':id',
            name: RouteNames.VENDORS_SINGLE,
            component: () =>
              import('@/features/Vendors/SingleView/VendorSingle.vue')
          },
          {
            path: 'request',
            name: RouteNames.VENDORS_REQUEST,
            component: () =>
              import('@/features/Vendors/RequestForm/VendorRequestForm.vue')
          }
        ]
      },
      {
        path: '/my-profile',
        name: RouteNames.MY_PROFILE,
        component: () => import('@/views/dashboard/settings/MyProfile.vue')
      },
      {
        path: '/settings',
        name: RouteNames.SETTINGS,
        redirect: { name: RouteNames.SETTINGS_WORKSPACE },
        children: [
          {
            path: 'institutions',
            name: RouteNames.SETTINGS_INSTITUTIONS,
            component: () =>
              import('@/views/dashboard/settings/Institutions.vue')
          },
          {
            path: 'accounts',
            name: RouteNames.SETTINGS_ACCOUNTS,
            component: () =>
              import('@/views/dashboard/settings/AccountsNew.vue')
          },
          {
            path: 'accounts/connect',
            name: RouteNames.SETTINGS_ADD_ACCOUNT,
            component: () =>
              import('@/views/dashboard/settings/ConnectAccount.vue')
          },
          {
            path: 'entities',
            name: RouteNames.SETTINGS_ENTITIES,
            component: () => import('@/features/Settings/Entities/Entities.vue')
          },
          {
            path: 'connections',
            children: [
              {
                path: '',
                name: RouteNames.SETTINGS_EBICS,
                component: () =>
                  import('@/views/dashboard/settings/Connections.vue')
              },
              {
                path: 'statements/:id',
                name: RouteNames.SETTINGS_STATEMENTS,
                component: () =>
                  import('@/views/dashboard/settings/EbicsStatements.vue')
              }
            ]
          },
          {
            path: 'account-groups',
            name: RouteNames.SETTINGS_ACCOUNT_GROUPS,
            component: () =>
              import('@/views/dashboard/settings/AccountGroups.vue')
          },
          {
            path: 'users-management',
            name: RouteNames.SETTINGS_USERS_MANAGEMENT,
            component: () =>
              import('@/features/Settings/UserManagement/UserManagement.vue')
          },
          {
            path: 'users-management/delegations',
            name: RouteNames.SETTINGS_DELEGATIONS,
            component: () =>
              import('@/views/dashboard/settings/Delegations.vue')
          },
          {
            path: 'automatic-categorizations',
            name: RouteNames.SETTINGS_AUTOMATIC_CATEGORIZATIONS,
            component: () =>
              import(
                '@/views/dashboard/settings/AutomaticCategorizationsNew.vue'
              )
          },
          {
            path: 'analytical-axes',
            name: RouteNames.SETTINGS_ANALYTICAL_AXES,
            component: () =>
              import('@/features/Settings/AnalyticalAxes/AnalyticalAxes.vue')
          },
          {
            path: 'catalog',
            name: RouteNames.SETTINGS_CATALOG,
            component: () =>
              import('@/features/Settings/Catalog/CatalogView.vue')
          },
          {
            path: 'analytical-axes/erp-mapping',
            name: RouteNames.SETTINGS_ERP_MAPPING,
            component: () =>
              import(
                '@/features/Settings/AnalyticalAxes/Mapping/ERPMapping.vue'
              )
          },
          {
            path: 'analytical-axes/items-mapping',
            name: RouteNames.SETTINGS_ITEMS_MAPPING,
            component: () =>
              import(
                '@/features/Settings/AnalyticalAxes/Mapping/ItemsMapping.vue'
              )
          },
          {
            path: 'analytical-axes/provider-mapping',
            name: RouteNames.SETTINGS_PROVIDER_MAPPING,
            component: () =>
              import(
                '@/features/Settings/AnalyticalAxes/Mapping/ProviderMapping.vue'
              )
          },
          {
            path: 'notifications',
            name: RouteNames.SETTINGS_NOTIFICATIONS,
            component: () =>
              import('@/views/dashboard/settings/SettingsNotifications.vue')
          },
          {
            path: 'forms',
            children: [
              {
                path: '',
                name: RouteNames.SETTINGS_FORMS,
                component: () => import('@/views/dashboard/settings/Forms.vue')
              }
            ]
          },
          {
            path: 'form-builder',
            name: RouteNames.SETTINGS_FORM_BUILDER,
            component: () =>
              import('@/views/dashboard/settings/FormBuilder.vue')
          },
          {
            path: 'form-builder-vendor',
            name: RouteNames.SETTINGS_FORM_BUILDER_VENDOR,
            component: () =>
              import('@/features/Settings/Forms/Vendor/FormBuilderVendor.vue')
          },

          {
            path: 'router-builder',
            name: RouteNames.SETTINGS_ROUTER_BUILDER,
            component: () =>
              import('@/components/Intake/RouterBuilder/RouterBuilderView.vue')
          },
          {
            path: 'router-builder-dev',
            name: RouteNames.SETTINGS_ROUTER_BUILDER_DEV,
            component: () =>
              import('@/components/Intake/RouterBuilder/RouterBuilderDev.vue')
          },
          {
            path: 'virtual-cards',
            children: [
              {
                path: '',
                name: RouteNames.SETTINGS_VIRTUAL_CARDS,
                component: () =>
                  import('@/features/Settings/VirtualCards/VirtualCards.vue')
              },
              {
                path: 'users',
                name: RouteNames.SETTINGS_VIRTUAL_CARDS_USERS,
                component: () =>
                  import(
                    '@/features/Settings/VirtualCards/VirtualCardsUsers.vue'
                  )
              }
            ]
          },
          {
            path: 'payment-file-presets',
            name: RouteNames.SETTINGS_PAYMENT_FILE_PRESETS,
            component: () =>
              import(
                '@/features/Settings/PaymentFilePresets/View/Table/PaymentFilePresets.vue'
              )
          },
          {
            path: 'new-payment-file-presets',
            name: RouteNames.SETTINGS_NEW_PAYMENT_FILE_PRESETS,
            component: () =>
              import(
                '@/features/Settings/PaymentFilePresets/View/Create/NewPaymentFilePresets.vue'
              )
          },
          {
            path: 'edit-payment-file-presets/:id',
            name: RouteNames.SETTINGS_EDIT_PAYMENT_FILE_PRESETS,
            component: () =>
              import(
                '@/features/Settings/PaymentFilePresets/View/Edit/EditPaymentFilePresets.vue'
              )
          },
          {
            path: 'workspace',
            name: RouteNames.SETTINGS_WORKSPACE,
            component: () =>
              import('@/features/Settings/Workspace/Workspace.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/connectors',
    children: [
      {
        path: 'slack/redirect',
        component: () => import('@/views/redirect/Slack.vue')
      },
      {
        path: 'banking/redirect',
        component: () => import('@/views/redirect/Banking.vue')
      },
      {
        path: 'banking/klarna',
        component: () => import('@/views/redirect/Klarna.vue')
      },
      {
        path: 'banking/provider-creds',
        name: RouteNames.CONNECTOR_CREDS,
        component: () => import('@/views/redirect/ProviderConnectorCreds.vue')
      },
      {
        path: 'banking/adyen',
        component: () => import('@/views/redirect/Adyen.vue')
      },
      {
        path: 'banking/paypal',
        component: () => import('@/views/redirect/PayPal.vue')
      }
    ]
  },
  {
    path: '/onboarding/redirect/',
    component: () => import('@/views/redirect/Onboarding.vue')
  },
  {
    path: '/resolve-auth-swan',
    name: RouteNames.RESOLVE_AUTH_SWAN,
    component: () => import('@/views/redirect/ResolveAuthSwan.vue')
  },
  {
    path: '/auto-close',
    component: () => import('@/views/redirect/AutoClose.vue')
  },
  ...redirectRoutes,
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/NotFound.vue')
  }
];

export default routes;
