import { API } from 'aws-amplify';

import { AnalyticalAxisType } from '@/custom-types/analytical-axes';
import { getTenantId, getUserJwtToken } from '@/helpers/amplify';

import {
  CounterpartyWorkflowDependency,
  TriggerActionType,
  TriggerConnectorType
} from './schemas/approval';

export enum StepType {
  APPROVAL = 'APPROVAL',
  EXTERNAL_APPROVAL = 'EXTERNAL_APPROVAL',
  WAIT_FOR = 'WAIT_FOR',
  TRIGGER = 'TRIGGER'
}

export enum ApproverType {
  ANALYTICAL_AXIS_NODE = 'ANALYTICAL_AXIS_NODE',
  ANALYTICAL_AXIS_OWNER = 'ANALYTICAL_AXIS_OWNER',
  USER = 'USER',
  DYNAMIC_USER = 'DYNAMIC_USER',
  SETTINGS_ROLE = 'SETTINGS_ROLE'
}

export interface ApproverAPIAnalyticalAxisNodeMember {
  type: ApproverType.ANALYTICAL_AXIS_NODE;
  analytical_axis_node_id: number;
}

export interface ApproverAPIAnalyticalAxisOwner {
  type: ApproverType.ANALYTICAL_AXIS_OWNER;
  analytical_axis_type: AnalyticalAxisType;
  user_ids: number[];
}

export interface ApproverAPIUser {
  type: ApproverType.USER;
  user_id: number;
}

export enum DynamicUserType {
  PO_REQUESTER = 'PO_REQUESTER'
}

export interface ApproverAPIDynamicUser {
  type: ApproverType.DYNAMIC_USER;
  user_id?: number;
  dynamic_user_type: DynamicUserType.PO_REQUESTER;
}

export interface ApproverAPISettingsRole {
  type: ApproverType.SETTINGS_ROLE;
  role: string;
}

export type ApproverAPIUnion =
  | ApproverAPIAnalyticalAxisNodeMember
  | ApproverAPIAnalyticalAxisOwner
  | ApproverAPIUser
  | ApproverAPISettingsRole
  | ApproverAPIDynamicUser;

export enum StepInstanceStatus {
  PENDING_DECISION = 'PENDING_DECISION',
  PENDING_RESULT = 'PENDING_RESULT',
  PENDING_DEPENDENCY = 'PENDING_DEPENDENCY',
  PENDING_WAIT_FOR = 'PENDING_WAIT_FOR',
  PENDING_EXTERNAL_APPROVAL = 'PENDING_EXTERNAL_APPROVAL',
  EXTERNAL_APPROVAL_APPROVED = 'EXTERNAL_APPROVAL_APPROVED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  EXTERNAL_APPROVAL_REJECTED = 'EXTERNAL_APPROVAL_REJECTED',
  SKIPPED = 'SKIPPED',
  CANCELLED = 'CANCELLED',
  COMPUTED = 'COMPUTED'
}

export interface StepDependenciesInstanceOutput {
  service: string;
  id: number;
}

export interface DecisionMetadata {
  decision_at: string; // "2024-01-09T14:11:48.216501",
  decision_by: number; // 1,
}

export interface StepInstanceOutput {
  step_id: number;
  step_instance_id: number;
  type: StepType;
  status: StepInstanceStatus;
  approvers: ApproverAPIUnion[];
  condition_dependencies: StepDependenciesInstanceOutput[];
  wait_for_dependencies: StepDependenciesInstanceOutput[];
  decision_metadata: DecisionMetadata;
  workflow_dependencies?: CounterpartyWorkflowDependency[];
  name: string;
  expected_skipped: boolean;
  connector?: {
    value: {
      type: TriggerConnectorType;
      action: {
        type: TriggerActionType;
        users_ids?: number[];
      };
    };
  };
}

export interface BlockInstanceOutput {
  id: number;
  position: number;
  name?: string;
  steps: StepInstanceOutput[];
}

export interface ActionStepInstanceIds {
  approve: number[];
  reject: number[];
}

export enum WorkflowInstanceStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED'
}

export interface SimpleWorkflowInstanceOutput {
  id: number;
  status: WorkflowInstanceStatus;
  action_step_instance_ids: ActionStepInstanceIds;
}

interface DetailedWorkflowInstanceOutput extends SimpleWorkflowInstanceOutput {
  blocks: BlockInstanceOutput[];
  step_instances?: {
    count_all: number;
    count_approved: number;
    to_decide_ids: number[];
  };
}

interface CreateWorkflowInstanceBody {
  workflow_id: number;
}

export interface CreateWorkflowInstanceOutput {
  workflow_instance_id: number;
}

export type GetWorkflowInstanceOutput = DetailedWorkflowInstanceOutput;

export enum DelegationDisplayStatus {
  ONGOING = 'ONGOING',
  UPCOMING = 'UPCOMING',
  REVOKED = 'REVOKED'
}

export interface GetDelegationsQuery {
  display_status_in?: DelegationDisplayStatus[];
  source_id_in?: number[];
  target_id_in?: number[];
  source_or_target_id_in?: number[];
  end_date_gte?: string;
  start_date_lt?: string;
  end_date_lte?: string;
  start_date_gte?: string;
  by_start_date?: 'ASC' | 'DESC';
  page: number;
  page_size: number;
}

export interface Delegation {
  id?: number;
  source: number;
  target: number;
  start_date: string;
  end_date: string;
  active?: boolean;
  display_status?: DelegationDisplayStatus;
}

export interface DelegationNew {
  source: number;
  target: number;
  start_date: string;
  end_date: string;
}

export interface GetDelegationsOutput {
  delegations: Delegation[];
  pagination: {
    has_more: boolean;
    next_page: number;
    previous_page: number;
  };
}
export const getDelegations = async (
  queryStringParameters: GetDelegationsQuery
): Promise<GetDelegationsOutput> => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.get('approval', `/rest/v1/${tenantId}/delegations`, {
    queryStringParameters: JSON.parse(JSON.stringify(queryStringParameters)),
    headers: header
  });
};

export interface PostDelegationBody {
  delegations: Delegation[];
}

export const postDelegations = async (
  body: PostDelegationBody
): Promise<any> => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.post('approval', `/rest/v1/${tenantId}/delegations`, {
    body,
    headers: header
  });
};

export interface PutDelegationBody {
  source: number;
  target: number;
  start_date: string;
  end_date: string;
}

export const putDelegation = async (
  delegationId,
  body: PutDelegationBody
): Promise<any> => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.put(
    'approval',
    `/rest/v1/${tenantId}/delegations/${delegationId}`,
    { body, headers: header }
  );
};

export const deleteDelegation = async (delegationId): Promise<any> => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.del(
    'approval',
    `/rest/v1/${tenantId}/delegations/${delegationId}`,
    { headers: header }
  );
};

export const getDelegation = async (delegationId): Promise<any> => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.get(
    'approval',
    `/rest/v1/${tenantId}/delegations/${delegationId}`,
    { headers: header }
  );
};

export enum ScopeAccess {
  RESTRICTED = 'RESTRICTED',
  UNRESTRICTED = 'UNRESTRICTED',
  OWN_REQUESTS = 'OWN_REQUESTS'
}

export type GetApprovalRequestUserScopesOutput = {
  user_id: number;
  requesting?: {
    cost_center: {
      analytical_axis_ids: number[];
      scope_type: ScopeAccess;
    };
    entity: {
      entity_ids: number[];
      scope_type: ScopeAccess;
    };
  };
  visibility?: {
    cost_center: {
      analytical_axis_ids: number[];
      scope_type: ScopeAccess;
    };
    entity: {
      entity_ids: number[];
      scope_type: ScopeAccess;
    };
  };
  entity_ids?: number[];
};

export const getApprovalRequestUserScopes = async (
  userId: number
): Promise<GetApprovalRequestUserScopesOutput> => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  return API.get(
    'approval',
    `/rest/v1/${tenantId}/users/${userId}/request-scopes`,
    { headers: header }
  );
};

export type PutApprovalRequestUserScopesBody = {
  user_id: number;
  request_entity_scope_access: ScopeAccess;
  request_cost_center_scope_access: ScopeAccess;
  analytical_axis_ids: number[];
  entity_ids: number[];
};

export const putApprovalRequestUserScopes = async (
  userId: number,
  body: PutApprovalRequestUserScopesBody
): Promise<any> => {
  const tenantId = await getTenantId();
  const header = { token: await getUserJwtToken() };

  const sanitizedBody = body;

  // This is old api that will be deprecated
  delete sanitizedBody.analytical_axis_ids;
  delete sanitizedBody.entity_ids;
  delete sanitizedBody.request_entity_scope_access;
  delete sanitizedBody.request_cost_center_scope_access;

  return API.put(
    'approval',
    `/rest/v1/${tenantId}/users/${userId}/request-scopes`,
    { body: sanitizedBody, headers: header }
  );
};
