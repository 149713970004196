import { ref, watch } from 'vue';
import { createI18n } from 'vue-i18n';

const availableLanguages = ['en', 'es', 'fr', 'pt', 'it', 'pl'];

const loadedLanguages = ref<string[]>([]);

const getDefaultLang = () => {
  const urlLang = new URLSearchParams(window.location.search).get('hl');
  if (availableLanguages.includes(urlLang)) {
    return urlLang;
  }

  const stored = localStorage.getItem('preferred_language');
  if (availableLanguages.includes(stored as string)) {
    return stored;
  }

  const langWithoutRegionCode = navigator.language?.slice(0, 2);
  return availableLanguages.includes(langWithoutRegionCode)
    ? langWithoutRegionCode
    : 'en';
};

const setI18nLanguage = (lang: string) => {
  i18n.global.locale.value = lang as any;
  document.querySelector('html')?.setAttribute('lang', lang);
  localStorage.setItem('preferred_language', lang);
  return lang;
};

async function loadLanguageAsync(lang: string) {
  if (loadedLanguages.value.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  const messages = await import(`../constants/locales/${lang}.json`);
  i18n.global.setLocaleMessage(lang, messages.default);
  loadedLanguages.value.push(lang);
  return Promise.resolve(setI18nLanguage(lang));
}

export const currentLanguage = ref(getDefaultLang());

export const initI18n = async () => {
  await loadLanguageAsync(currentLanguage.value);
};

export const i18n = createI18n({
  legacy: false,
  locale: currentLanguage.value,
  fallbackLocale: 'en',
  messages: {},
  warnHtmlMessage: false
});

watch(
  currentLanguage,
  async (lang) => {
    await loadLanguageAsync(lang);
  },
  { immediate: true }
);
