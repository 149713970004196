import { API } from '@aws-amplify/api';

import {
  AckSubtype,
  ConnectorChannel,
  CreateConnectorBankingEbicsInput,
  CreateConnectorBankingInvestmentInput,
  CreateConnectorBankingInvestmentOutput,
  CreateSftpConnectionInputBody,
  CreateSubConnectionEbicsBodyInput,
  EBICS_TYPE,
  EditConnectionEbicsBodyInput,
  FileType,
  GetConnectorAccountsOutput,
  GetConnectorMandatoryFieldsResponse,
  GetEbicsTransactionsFilesListOutput,
  GetEbicsTransactionsFilesListQuery,
  GetERPSyncErrorOutput,
  GetSftpConnectionsOutput,
  PostConnectorMandatoryFieldsBody,
  RetrieveConnectorBankingInstitutionsQuery,
  RetrieveInstitutionsOutput,
  StatementSubtype,
  UpdateInvestmentAccountInput
} from '@/api/schemas/connector';
import { SourceSortBy } from '@/custom-types/connector';
import { ConnectorAuthenticateOutput } from '@/custom-types/connectors';
import { FileTypes, TransactionFileStatus } from '@/custom-types/ebics';
import { SortByOrder } from '@/custom-types/sorting';
import {
  getSessionPayload,
  getTenantId,
  getUserJwtToken
} from '@/helpers/amplify';

import { AWSDocument } from './schemas/common';

export async function getBankingConnectors() {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('connector', `/rest/v1/${tenantId}/connectors/banking`, {
    headers
  });
}

export async function postBankingSuccess(
  body
): Promise<ConnectorAuthenticateOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'connector',
    `/rest/v1/${tenantId}/connectors/banking/authentication`,
    { headers, body }
  );
}

export async function sendToken(
  connectorId,
  token
): Promise<ConnectorAuthenticateOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = { bearer_token: token, connector_id: connectorId };

  return API.post(
    'connector',
    `/rest/v1/${tenantId}/connectors/banking/authentication`,
    { headers, body }
  );
}

export async function authenticateAdyenConnector(
  connectorId: number,
  username: string,
  password: string
): Promise<ConnectorAuthenticateOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = {
    connector_id: connectorId,
    basic_auth_username: username,
    basic_auth_password: password
  };

  return API.post(
    'connector',
    `/rest/v1/${tenantId}/connectors/banking/authentication`,
    { headers, body }
  );
}

export async function authenticatePayPalConnector(
  connectorId: number,
  clientId,
  clientSecret
): Promise<ConnectorAuthenticateOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = {
    connector_id: connectorId,
    client_id: clientId,
    client_secret: clientSecret
  };

  return API.post(
    'connector',
    `/rest/v1/${tenantId}/connectors/banking/authentication`,
    { headers, body }
  );
}

export async function getCountries() {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('connector', `/rest/v1/${tenantId}/countries`, { headers });
}

export async function getInstitutions(
  countryCode?: string,
  channel?: ConnectorChannel[]
): Promise<RetrieveInstitutionsOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const query: RetrieveConnectorBankingInstitutionsQuery = {};

  if (countryCode) {
    query.country_code = countryCode;
  }
  if (channel) {
    query.channel_in = channel;
  }

  return API.get(
    'connector',
    `/rest/v1/${tenantId}/connectors/banking/institutions`,
    { queryStringParameters: query, headers }
  );
}

export async function getInstitution(institutionId: number): Promise<any> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'connector',
    `/rest/v1/${tenantId}/connectors/banking/institutions/${institutionId}`,
    { headers }
  );
}

export async function redirectToConnection(institutionId: number) {
  const sessionPayload = await getSessionPayload();
  const headers = { token: await getUserJwtToken() };
  const body = {
    client_id: sessionPayload.client_id,
    institution_id: institutionId
  };

  return API.post(
    'connector',
    `/rest/v1/${sessionPayload.tenant_id}/connectors/banking`,
    { headers, body }
  );
}

export async function refreshConnector(connectorId: number) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'connector',
    `/rest/v1/${tenantId}/connectors/banking/${connectorId}`,
    { headers }
  );
}

export async function disconnectConnector(connectorId: number) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'connector',
    `/rest/v1/${tenantId}/connectors/banking/${connectorId}/mark-as-expired`,
    { headers }
  );
}

export async function getAccounts(): Promise<GetConnectorAccountsOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('connector', `/rest/v1/${tenantId}/accounts`, { headers });
}

export async function getFundingSources(
  creditorAccountId: number,
  sortBy = SourceSortBy.ACCOUNT_NAME,
  sortByOrder = SortByOrder.ASC,
  page = 1
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const queryStringParameters = {
    creditor_account_id: creditorAccountId,
    sort_by: sortBy,
    sort_direction: sortByOrder,
    page_size: 40,
    page
  };

  return API.get('connector', `/rest/v1/${tenantId}/funding-sources`, {
    headers,
    queryStringParameters
  });
}

export async function addFundingSource(
  creditorAccountId: number,
  debtorAccountId: number,
  debtorAccountName
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = {
    creditor_account_id: creditorAccountId,
    debtor_account_id: debtorAccountId,
    name: debtorAccountName,
    consent_redirect_url: window.location.href
  };

  return API.post('connector', `/rest/v1/${tenantId}/funding-sources`, {
    headers,
    body
  });
}

export async function revokeFundingSource(fundingSourceId: number) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.del(
    'connector',
    `/rest/v1/${tenantId}/funding-sources/${fundingSourceId}`,
    { headers }
  );
}

export async function downloadAccountStatements(
  accountId: number,
  openingDate: string,
  closingDate: string,
  language = 'en'
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };
  const body = {
    account_id: accountId,
    opening_date: openingDate,
    closing_date: closingDate,
    statement_type: 'CSV',
    language
  };

  return API.post('connector', `/rest/v1/${tenantId}/account-statements`, {
    headers,
    body
  });
}

export async function getCATAccountsData() {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'connector',
    `/rest/v1/${tenantId}/accounts/investment?with_total_interests=true`,
    { headers }
  );
}

export async function createInvestmentAccount(
  body: CreateConnectorBankingInvestmentInput
): Promise<CreateConnectorBankingInvestmentOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'connector',
    `/rest/v1/${tenantId}/connectors/banking/investment`,
    { headers, body }
  );
}

export async function deleteInvestmentAccount(accountId: number) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.del(
    'connector',
    `/rest/v1/${tenantId}/accounts/${accountId}/investment`,
    { headers }
  );
}

export async function closeInvestmentAccount(
  accountId: number,
  closingDate: string
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'connector',
    `/rest/v1/${tenantId}/accounts/${accountId}/investment/close`,
    { headers, body: { closing_date: closingDate } }
  );
}

export async function rollInvestmentAccount(
  accountId: number,
  body: UpdateInvestmentAccountInput
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'connector',
    `/rest/v1/${tenantId}/accounts/${accountId}/investment/roll`,
    { headers, body }
  );
}

export async function createEbicsAccount(
  body: CreateConnectorBankingEbicsInput
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'connector',
    `/rest/v1/${tenantId}/connectors/banking/ebics`,
    { headers, body }
  );
}

export interface connectorsEbicsT {
  id: number;
  name: string;
  status: string;
  ebics_host_id: string;
  ebics_partner_id: string;
  ebics_user_id: string;
  ebics_url: string;
  ebics_version: string;
  ebics_camt_format: string;
  ebics_cfonb_format: string;
  ebics_mt940_format: string;
  ebics_ack_format: string;
  ebics_ara_format: string;
  country_code: string;
  ebics_extension_id: number;
  is_main_connection: boolean;
  ebics_sct_international_format: string;
  ebics_sct_sepa_format: string;
  ebics_sct_treasury_format: string;
  ebics_sdd_b2b_format: string;
  ebics_sdd_core_format: string;
  letter_bucket?: string;
  letter_key?: string;
  ebics_type: EBICS_TYPE;
  ebics_standard_statement_type: FileTypes;
}

export interface connectorsEbicsTS {
  id: number;
  name: string;
  status: string;
  ebics_host_id: string;
  ebics_partner_id: string;
  ebics_user_id: string;
  ebics_url: string;
  ebics_version: string;
  ebics_camt_format: string;
  ebics_cfonb_format: string;
  ebics_mt940_format: string;
  ebics_ack_format: string;
  ebics_ara_format: string;
  country_code: string;
  ebics_type: EBICS_TYPE;
  ebics_extension_id: number;
  ebics_sct_international_format: string;
  ebics_sct_sepa_format: string;
  ebics_sct_treasury_format: string;
  ebics_sdd_b2b_format: string;
  ebics_sdd_core_format: string;
  letter_bucket?: string;
  letter_key?: string;
  is_main_connection: boolean;
  ebics_standard_statement_type: FileTypes;
}

export interface GetEbicsTConnectionsOutput {
  connectors: connectorsEbicsT[];
}
export const getEbicsTConnections =
  async (): Promise<GetEbicsTConnectionsOutput> => {
    const tenantId = await getTenantId();
    const headers = { token: await getUserJwtToken() };

    return API.get('connector', `/rest/v1/${tenantId}/connectors/ebics`, {
      headers
    });
  };

export const getSftpConnections =
  async (): Promise<GetSftpConnectionsOutput> => {
    const tenantId = await getTenantId();
    const headers = { token: await getUserJwtToken() };

    return API.get('connector', `/rest/v1/${tenantId}/connectors/sftp`, {
      headers
    });
  };

export async function createSftpConnection(
  body: CreateSftpConnectionInputBody
): Promise<GetSftpConnectionsOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post('connector', `/rest/v1/${tenantId}/connectors/sftp`, {
    headers,
    body
  });
}

export interface CreateEbicsTConnectionInput {
  institution_id: number;
  client_id: number;
}

export interface CreateEbicsTConnectionOutput {
  id: number;
}

export async function createEbicsTConnection(
  body: CreateEbicsTConnectionInput
): Promise<CreateEbicsTConnectionOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'connector',
    `/rest/v1/${tenantId}/connectors/banking/ebics-t`,
    { headers, body }
  );
}

export interface CreateEbicsTSConnectionInput {
  institution_id: number;
  client_id: number;
}

export interface CreateEbicsTSConnectionOutput {
  id: number;
}

export async function createEbicsTSConnection(
  body: CreateEbicsTSConnectionInput
): Promise<CreateEbicsTSConnectionOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'connector',
    `/rest/v1/${tenantId}/connectors/banking/ebics-ts`,
    { headers, body }
  );
}

export interface InitAuthenticationEbicsTConnectionInput {
  ebics_host_id: string;
  ebics_partner_id: string;
  ebics_user_id: string;
  ebics_url: string;
  ebics_version: string;
  ebics_camt_format: string;
  ebics_cfonb_format: string;
  ebics_mt940_format: string;
  ebics_ack_format: string;
  ebics_ara_format: string;
  country_code: string;
  ebics_sct_sepa_format?: string;
  ebics_sct_treasury_format?: string;
  ebics_sct_international_format?: string;
  ebics_sdd_core_format?: string;
  ebics_sdd_b2b_format?: string;
  ebics_standard_statement_type: FileTypes;
}

export interface InitAuthenticationEbicsTConnectionOutput {
  letter_bucket: string;
  letter_path: string;
}

export async function initAuthenticationEbicsTConnection(
  connectorId: number,
  body: InitAuthenticationEbicsTConnectionInput
): Promise<InitAuthenticationEbicsTConnectionOutput> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.post(
    'connector',
    `/rest/v1/${tenantId}/connectors/ebics/${connectorId}/init-authentication`,
    { headers, body }
  );
}

export const getEbicsTransactionsFilesList = async (
  query: GetEbicsTransactionsFilesListQuery
): Promise<GetEbicsTransactionsFilesListOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = query
    ? JSON.parse(JSON.stringify(query))
    : undefined;

  return API.get('connector', `/rest/v1/${tenantId}/transaction-files`, {
    headers,
    queryStringParameters
  });
};

export interface GetEbicsTransactionsFilesBulkAsyncQuery {
  date_gte?: string;
  date_lte?: string;
  transaction_type: string;
  status_in?: TransactionFileStatus[];
  connector_id: number;
}

export const getEbicsTransactionsFilesBulkAsync = async (
  query: GetEbicsTransactionsFilesBulkAsyncQuery
): Promise<void> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const queryStringParameters = query
    ? JSON.parse(JSON.stringify(query))
    : undefined;

  return API.get(
    'connector',
    `/rest/v1/${tenantId}/transaction-files/single-output`,
    { headers, queryStringParameters }
  );
};
export interface GetEbicsTransactionFileOutput {
  id: number;
  connector_id: number;
  type: FileType;
  subtype: AckSubtype | StatementSubtype;
  status: TransactionFileStatus;
  created_at: string;
  reference?: string;
  aws_document: AWSDocument;
}

export const getEbicsTransactionFile = async (
  transactionFileId: number
): Promise<GetEbicsTransactionFileOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'connector',
    `/rest/v1/${tenantId}/transaction-files/${transactionFileId}`,
    { headers }
  );
};

export interface getEbicsConnectionInfoOutput {
  country_code: string;
  ebics_ack_format: string;
  ebics_ara_format: string;
  ebics_bank_credentials: string;
  ebics_camt_format: string;
  ebics_cfonb_format: string;
  ebics_client_credentials: string;
  ebics_host_id: string;
  ebics_partner_id: string;
  ebics_passphrase: string;
  ebics_sct_international_format: string;
  ebics_sct_sepa_format: string;
  ebics_sct_treasury_format: string;
  ebics_sdd_b2b_format: string;
  ebics_sdd_core_format: string;
  ebics_url: string;
  ebics_user_id: string;
  ebics_version: string;
  id: number;
  name: string;
  status: string;
  created_at: string;
  ebics_type: EBICS_TYPE;
}

export const getEbicsIndirectConnectionInfo = async (
  id: number
): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'connector',
    `/rest/v1/${tenantId}/connectors/ebics/indirect/${id}`,
    { headers }
  );
};

export const getEbicsConnectionInfo = async (id: number): Promise<any> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('connector', `/rest/v1/${tenantId}/connectors/ebics/${id}`, {
    headers
  });
};

export async function createSubConnectionEbics(
  body: CreateSubConnectionEbicsBodyInput
): Promise<any> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const parsedBody = JSON.parse(JSON.stringify(body));

  return API.post(
    'connector',
    `/rest/v1/${tenantId}/connectors/ebics/indirect`,
    { headers, body: parsedBody }
  );
}

export async function editSubConnectionEbics(
  id: number,
  body: EditConnectionEbicsBodyInput
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.patch(
    'connector',
    `/rest/v1/${tenantId}/connectors/ebics/indirect/${id}`,
    { headers, body }
  );
}

export async function editConnectionEbics(
  id: number,
  body: EditConnectionEbicsBodyInput
) {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.patch('connector', `/rest/v1/${tenantId}/connectors/ebics/${id}`, {
    headers,
    body
  });
}

export async function getConnectorMandatoryFields(
  connectorId: string
): Promise<GetConnectorMandatoryFieldsResponse> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get(
    'connector',
    `/rest/v1/${tenantId}/connectors/banking/${connectorId}/mandatory-fields`,
    {
      headers
    }
  );
}

export async function postConnectorMandatoryFields(
  body: PostConnectorMandatoryFieldsBody
): Promise<any> {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  const parsedBody = JSON.parse(JSON.stringify(body));

  return API.post(
    'connector',
    `/rest/v1/${tenantId}/connectors/banking/authentication`,
    { headers, body: parsedBody }
  );
}

export const getERPSyncError = async (): Promise<GetERPSyncErrorOutput> => {
  const tenantId = await getTenantId();
  const headers = { token: await getUserJwtToken() };

  return API.get('connector', `/rest/v1/${tenantId}/sync-errors`, {
    headers
  });
};
