import { captureException } from '@sentry/browser';
import * as Sentry from '@sentry/vue';
import axios from 'axios';
import { ref } from 'vue';

const cleanRequestData = (request) => {
  try {
    const parsedRequest = JSON.parse(request);
    return parsedRequest;
  } catch (err) {
    return request;
  }
};

const cleanPath = (path: string) => {
  if (!path) return '';
  return path.replace(/\/\d+/g, '/:id').replace(/\?.+$/, '');
};

const getErrorInfo = (error: unknown, event: any) => {
  // Base error info structure
  const baseErrorInfo = {
    tags: {
      page_path: cleanPath(event.transaction)
    }
  };

  if (!error) {
    return {};
  }

  // Handle Axios errors
  if (axios.isAxiosError(error)) {
    return {
      ...baseErrorInfo,
      message: error.response?.data?.message || error.message,
      contexts: {
        api: {
          request: cleanRequestData(error.response?.config?.data),
          response: error.response?.data,
          request_url: error.config?.url
        }
      },
      tags: {
        ...baseErrorInfo.tags,
        error_type: 'axios',
        request_path: cleanPath((error.config as any)?.path),
        request_method: error.config?.method,
        response_status: error.response?.status,
        response_code: error.response?.data?.type
      }
    };
  }

  // Handle standard Error objects
  if (error instanceof Error) {
    return {
      ...baseErrorInfo,
      message: error.toString()
    };
  }

  // Handle DOM error events
  if (error instanceof Event && error.type === 'error') {
    return {
      ...baseErrorInfo,
      message: (error.target as Element)?.outerHTML
    };
  }

  // Handle objects with message property
  if (typeof error === 'object' && 'message' in error && error.message) {
    return {
      ...baseErrorInfo,
      message:
        typeof error.message === 'object'
          ? JSON.stringify(error.message)
          : error.message
    };
  }

  // Handle any other error types
  return {
    ...baseErrorInfo,
    message:
      error && typeof error === 'object' ? error.toString() : 'undefined error'
  };
};

export default {
  install(app) {
    const integrations = [Sentry.browserTracingIntegration()];

    if (import.meta.env.VITE_IS_AMPLIFY === 'true')
      integrations.push(Sentry.replayIntegration());

    app.config.globalProperties.showRefreshModal = ref(false);

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DOMAIN,
      environment:
        import.meta.env.VITE_IS_MAIN === 'true' ? 'production' : 'development',
      integrations,
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      ignoreErrors: [
        'Network Error',
        'Non-Error promise rejection captured' // https://stackoverflow.com/questions/75536442/troubleshooting-non-error-promise-rejection-captured-with-value-object-not-fou
      ],
      beforeSend(event, hint) {
        const originalException: any = hint?.originalException;
        const message = originalException?.message;
        if (
          message?.includes('Failed to fetch dynamically imported module') ||
          message?.includes('error loading dynamically imported module') ||
          message?.includes('Unable to preload CSS') ||
          message?.includes('Importing a module script failed')
        ) {
          app.config.globalProperties.showRefreshModal.value = true;
        }

        console.error(originalException);

        const detail: any = getErrorInfo(originalException, event);

        const obj = {
          ...event,
          message: detail.message,
          tags: {
            ...event.tags,
            ...detail.tags
          },
          contexts: {
            ...event.contexts,
            ...detail.contexts
          }
        };

        if (import.meta.env.VITE_IS_AMPLIFY !== 'true') return null;
        return obj;
      }
    });

    app.config.errorHandler = (error, vm, info) => {
      captureException(error, {
        extra: {
          info
        }
      });
    };
  }
};
