<template>
  <div
    v-if="analyticalAxesStore.isPending"
    class="flex-col justify-center"
  >
    <DsSkeleton style="width: 90px" />
  </div>
  <DsInlineList
    v-else
    :ellipses="true"
    :values="sortedTags"
    :nb-of-line="numberOfLines ?? 20"
  >
    <template #value="element">
      <DsBreadcrumb
        :items="[
          analyticalAxesStore.getNodeName(element.analytical_axis_node_ids[0])
        ]"
        :color="
          analyticalAxesStore.getColor(element.analytical_axis_node_ids[0])
        "
        :no-pop="!editable"
        @pop="editable ? removeAxis(element.analytical_axis_id) : undefined"
      />
    </template>
    <template #ellipse="data">
      <DsBreadcrumb
        :items="['+' + data.value]"
        color="black"
        no-pop
      />
    </template>
  </DsInlineList>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount } from 'vue';

import { AnalyticalAxisAssoc } from '@/api/schemas/common';
import { useAnalyticalAxesStore } from '@/stores/data-models/analytical-axes';

import DsBreadcrumb from './DsBreadcrumb.vue';
import DsInlineList from './DsInlineList.vue';
import DsSkeleton from './DsSkeleton.vue';

const props = defineProps<{
  modelValue: AnalyticalAxisAssoc[];
  editable?: boolean;
  numberOfLines?: number;
}>();
const analyticalAxesStore = useAnalyticalAxesStore();

const emit = defineEmits<{
  'update:modelValue': [modelValue: AnalyticalAxisAssoc[]];
}>();

const removeAxis = (analyticalAxisId: number) => {
  emit(
    'update:modelValue',
    props.modelValue.filter((x) => x.analytical_axis_id !== analyticalAxisId)
  );
};

const sortedTags = computed(() =>
  props.modelValue
    ? [...props.modelValue].sort(
        (a, b) => a.analytical_axis_id - b.analytical_axis_id
      )
    : undefined
);

onBeforeMount(() => {
  analyticalAxesStore.lazyLoadAnalyticalTreesAndNodes();
});
</script>

<style lang="scss" scoped></style>
