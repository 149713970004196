<template>
  <PfPopin
    :visible="shouldShowRefreshModal"
    :title="$t('newVersionAvailable.title')"
    :description="$t('newVersionAvailable.description')"
    :cancelable="false"
    always-rendered
  >
    <template #footer>
      <DsButton
        color="blue"
        :loading="isLoading"
        @click="handleRefresh"
      >
        {{ $t('newVersionAvailable.validBtnText') }}
      </DsButton>
    </template>
  </PfPopin>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance, ref } from 'vue';

import DsButton from '../DesignSystem/DsButton.vue';
import PfPopin from '../NewDesignSystem/PfPopin/PfPopin.vue';

const isLoading = ref(false);

const { appContext } = getCurrentInstance();
const shouldShowRefreshModal = computed(
  () => appContext.config.globalProperties.showRefreshModal.value
);

const handleRefresh = async () => {
  isLoading.value = true;
  window.location.reload();
};
</script>
