<template>
  <div
    ref="topElement"
    class="ds-neo-dropdown"
    :class="{ disabled }"
  >
    <PfLabel
      :model-value="label"
      :required="required"
    />
    <div
      ref="dropdownView"
      class="ds-neo-dropdown-view"
    >
      <component
        :is="isSelect ? DsButton : 'div'"
        v-if="!noSelector"
        ref="openDropdownButtonRef"
        :data-test="`${dataTest}-button`"
        :color="color"
        :icon="icon"
        :small="small"
        :class="{
          'ds-neo-input': !isSelect,
          active: dropdownVisible,
          disabled: disabled,
          'small-pad': !!slots['value']
        }"
        style="justify-content: start; width: 100%"
        @click.stop.prevent="toggleVisible()"
      >
        <template v-if="searchInside">
          <input
            ref="searchInputInside"
            v-model="searchInput"
            class="input-inside"
            :disabled="disabled"
            :placeholder="placeholder"
            :data-test="`${dataTest}-input-field`"
            @click.stop.prevent="toggleVisible(true)"
            @input="
              selectedOptions = [];
              emit('update:modelValue', null);
            "
          />
        </template>
        <template v-else>
          <span
            v-if="pending"
            style="display: flex; margin: auto; margin-top: -2px"
          >
            <DsLoader :size="24" />
          </span>
          <span
            v-else-if="
              !selectedOptions ||
              selectedOptions.length === 0 ||
              (selectedOptions.length === 1 &&
                (selectedOptions[0] === null ||
                  selectedOptions[0] === undefined))
            "
            class="placeholder"
          >
            {{ placeholder }}
          </span>
          <template
            v-else-if="hasNoResultOption && modelValue === noResultOptionValue"
          >
            <slot name="no-result-value" />
          </template>
          <template v-else>
            <DsInlineList
              :only-one="onlyOne"
              :ellipses="ellipses"
              :values="selectedValues"
              :prevent-flex-wrap-in-field="preventFlexWrapInField"
            >
              <template #value="slotProps">
                <slot
                  v-if="slots['value']"
                  name="value"
                  v-bind="slotProps"
                />
                <span
                  v-else
                  style="white-space: nowrap"
                >
                  {{ slotProps.wording || slotProps.value || slotProps }}
                  <div
                    v-if="slotProps.secondaryWording"
                    class="secondary-wording"
                  >
                    {{ slotProps.secondaryWording }}
                  </div>
                </span>
              </template>
            </DsInlineList>
            <DsIcon
              v-if="!pending && clearButton && selectedOptions.length > 0"
              name="cross"
              size="small"
              @click.stop="
                emit('update:modelValue', null);
                selectedOptions = [];
              "
            />
          </template>
        </template>
        <DsIcon
          v-if="!isSelect && !pending"
          :name="dropdownVisible ? 'chevron-up' : 'chevron-down'"
          size="small"
          color="gray400"
          class="icon"
        />
      </component>
      <div
        v-if="dropdownVisible || isSelect || noSelector"
        v-show="dropdownVisible || noSelector"
        ref="container"
        class="ds-neo-dropdown-container"
        :class="{ loading }"
      >
        <div
          class="ds-dropdown-search-container"
          style="flex-grow: 1"
        >
          <div
            v-if="searchBox"
            class="ds-neo-input ds-dropdown-search"
            style="width: 100%"
            @click.stop.prevent="searchInputView.focus()"
          >
            <DsIcon
              name="search"
              size="big"
              color="gray400"
            />
            <input
              ref="searchInputView"
              v-model="searchInput"
              placeholder="Search..."
              :data-test="`${dataTest}-search-input`"
              @input="emit('update:search', searchInput)"
            />
          </div>

          <DsButton
            v-if="hasSelectAllButton && multiple"
            color="gray"
            type="button"
            style="flex-shrink: 0"
            @click.stop.prevent="onSelectAll"
          >
            {{
              isEverythingSelected
                ? $t('actions.unselectAll')
                : $t('actions.selectAll')
            }}
          </DsButton>
        </div>
        <slot name="before-choices" />
        <div
          ref="choicesView"
          class="ds-neo-dropdown-choices"
          :data-test="`${dataTest}-dropdown-options`"
        >
          <template
            v-for="([group, items], idx) of groups"
            :key="'group-' + group"
          >
            <div
              v-if="grouping"
              class="ds-neo-dropdown-group-title"
              :class="{ last: idx >= groups.length - 1 }"
              @click.stop.prevent="toggleFoldGroup(group)"
            >
              <div class="ds-neo-dropdown-group-title-content">
                <span>{{ group }}</span>
                <span>{{ groupsDetails?.[group] }}</span>
              </div>
              <DsIcon
                :name="foldedGroup[group] ? 'chevron-up' : 'chevron-down'"
                size="small"
                color="gray400"
              />
            </div>
            <div
              :ref="(el: any) => (groupsWrapperViews[group] = el)"
              class="ds-neo-dropdown-group-wrapper"
              :class="{ folded: foldedGroup[group] }"
            >
              <div
                :ref="(el: any) => (groupsViews[group] = el)"
                class="ds-neo-dropdown-group"
              >
                <DsNeoDropdownItem
                  v-for="(option, optionIndex) of items"
                  :key="option"
                  v-model:expanded="expandedOption[option.value]"
                  :multiple="multiple"
                  :option="option"
                  :checks="checks"
                  :data-test="`${dataTest}-dropdown-option-${optionIndex}`"
                  @click="option.disabled ? null : toggleChecked(toRaw($event))"
                >
                  <template
                    v-if="slots.item"
                    #default="item"
                  >
                    <slot
                      name="item"
                      v-bind="item"
                    />
                  </template>
                </DsNeoDropdownItem>
              </div>
            </div>
          </template>
          <div
            v-if="loading"
            class="loader"
          >
            <DsLoader :size="24" />
          </div>
          <div v-if="!loading && !filteredOptions?.length">
            <p class="no-result">
              {{ $t('common.no-results') }}
            </p>
          </div>
        </div>
        <div
          v-if="
            hasNoResultOption && searchInput.length && !filteredOptions.length
          "
          @click.stop.prevent="dropdownVisible = false"
        >
          <span class="no-result">
            <slot name="no-result-option"></slot>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { deburr } from 'lodash-es';
import {
  computed,
  getCurrentInstance,
  nextTick,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  toRaw,
  useSlots,
  watch
} from 'vue';

import PfLabel from '@/components/NewDesignSystem/PfLabel/PfLabel.vue';

import DsButton from './DsButton.vue';
import DsIcon from './DsIcon.vue';
import DsInlineList from './DsInlineList.vue';
import DsNeoDropdownItem from './DsNeoDropdownItem.vue';

const slots = useSlots();

const isColliding = function (element1, element2) {
  const rect1 = element1.getBoundingClientRect();
  const rect2 = element2.getBoundingClientRect();

  if (element1.style.display === 'none' || element2.style.display === 'none') {
    return false;
  }

  return (
    rect1.x < rect2.x + rect2.width &&
    rect1.x + rect1.width > rect2.x &&
    rect1.y < rect2.y + rect2.height &&
    rect1.y + rect1.height > rect2.y
  );
};

let intervalId = null;

const checkCollisions = function () {
  intervalId = setInterval(() => {
    document
      .querySelectorAll('.ds-neo-dropdown-group-title')
      .forEach((el: HTMLElement, idx: number, arr: NodeListOf<HTMLElement>) => {
        if (idx > 0 && isColliding(el, arr[idx - 1])) {
          for (let i = idx - 1; i >= 0; i--) {
            if (arr[i].style.display !== 'none') {
              arr[i].style.display = 'none';
            }
          }
        } else {
          if (el.style.display !== 'flex') {
            el.style.display = 'flex';
          }
          if (idx > 0 && arr[idx - 1].style.display !== 'flex') {
            arr[idx - 1].style.display = 'flex';
          }
        }
      });
  }, 100);
};

onUnmounted(() => {
  clearInterval(intervalId);
});

export interface NeoDropDownOption {
  value: string | number;
  wording: string;
  secondaryWording?: string;
  alwaysVisible?: boolean;
  group?: string;
  icon?: string;
  disabled?: boolean | string;
  items?: NeoDropDownOption[];
}

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    multiple?: boolean;
    label?: string;
    placeholder?: string;
    required?: boolean;
    pending?: boolean;
    loading?: boolean;
    options?: NeoDropDownOption[];
    groupsDetails?: { [key: string]: string };
    modelValue?: any | any[];
    ellipses?: boolean;
    searchInside?: boolean;
    searchBox?: boolean;
    hasSelectAllButton?: boolean;
    preventFlexWrapInField?: boolean;
    hasNoResultOption?: boolean;
    noResultOptionValue?: string | number;
    isSelect?: boolean;
    dontFilter?: boolean;
    color?: string;
    icon?: string;
    small?: boolean;
    zIndex?: number;
    fixedWidth?: boolean;
    placement?: string | 'center' | 'left' | 'right';
    clearButton?: boolean;
    hackFixCheckedCheckboxesThatDisplayUnchecked?: boolean;
    onlyOne?: boolean;
    collisionCheckingEnabled?: boolean;
    noSelector?: boolean;
    dataTest?: string;
  }>(),
  {
    disabled: false,
    multiple: false,
    label: null,
    placeholder: null,
    required: false,
    pending: false,
    loading: false,
    options: [] as any,
    groupsDetails: undefined,
    modelValue: null,
    ellipses: false,
    searchInside: false,
    searchBox: false,
    noResultOptionValue: null,
    isSelect: false,
    color: null,
    icon: null,
    small: false,
    zIndex: 1,
    fixedWidth: false,
    placement: 'center',
    onlyOne: false,
    collisionCheckingEnabled: false,
    noSelector: false,
    dataTest: ''
  }
);

const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: any): void;
  (e: 'show'): void;
  (e: 'hide'): void;
  (e: 'update:search', searchInput: any): void;
  (e: 'more'): void;
}>();

const dropdownVisible = ref(false);
const topElement = ref<HTMLElement>(null);
const dropdownView = ref<HTMLElement>(null);
const selectedOptions = ref([]);
const searchInputView = ref(null);
const searchInput = ref('');
const searchInputInside = ref(null);
const container = ref<HTMLElement>(null);
const parentScroll = ref(null);
const foldedGroup = ref({});
const expandedOption = ref({});
const choicesView = ref<HTMLElement>(null);
const groupsWrapperViews = ref<{ [key: string]: HTMLElement }>({});
const groupsViews = ref<{ [key: string]: HTMLElement }>({});
const openDropdownButtonRef = ref<HTMLElement>();

const isInfiniteList = computed(() => {
  return !!getCurrentInstance()?.vnode?.props?.onMore;
});

const onScroll = (ev) => {
  if (ev.target.contains(topElement.value) && container.value) {
    dropdownVisible.value = false;
  }
};

const onListScroll = (ev) => {
  if (
    ev.target.scrollTop + ev.target.clientHeight >=
    ev.target.scrollHeight - 1
  ) {
    emit('more');
  }
};

const toggleVisible = (forceVisibility?: boolean) => {
  if (props.disabled || props.pending) {
    dropdownVisible.value = false;
  } else if (forceVisibility !== undefined) {
    dropdownVisible.value = forceVisibility;
  } else {
    dropdownVisible.value = !dropdownVisible.value;
  }
  if (dropdownVisible.value) {
    nextTick(() => {
      autoplace(container.value);
    });
    if (props.searchInside && searchInputInside.value) {
      searchInput.value = '';
      searchInputInside.value.focus();
    } else if (props.searchBox) {
      searchInput.value = '';
      nextTick(() => {
        searchInputView.value.focus();
      });
    }
    emit('show');
  } else {
    if (props.searchInside && searchInputInside.value) {
      searchInput.value =
        findOptionByValue(selectedOptions.value[0])?.wording || '';
    }
    emit('hide');
  }
};

const selectedValues = computed(() => {
  const recurse = (parentList, value) => {
    for (const option of parentList) {
      if (toRaw(option.value) === value) {
        return option;
      }
      if (option.items?.length > 0) {
        const ret = recurse(option.items, value);
        if (ret) {
          return ret;
        }
      }
    }
    return null;
  };
  const ret = [];
  for (const value of selectedOptions.value) {
    const found = recurse(props.options, toRaw(value));
    if (found) {
      ret.push(found);
    }
  }
  return ret;
});

const autoplace = (elem: HTMLElement) => {
  const getScrollParent = (node) => {
    if (!node) {
      return null;
    }
    if (
      node !== topElement.value &&
      !topElement.value.contains(node) &&
      node.scrollHeight > node.clientHeight
    ) {
      return node;
    }
    return node.parentNode ? getScrollParent(node.parentNode) : null;
  };

  if (!topElement.value) {
    return;
  }

  parentScroll.value = getScrollParent(topElement.value);
  const baseLeft = 0;
  let left = baseLeft;
  const top =
    topElement.value.offsetTop +
    topElement.value.offsetHeight -
    (parentScroll.value?.scrollTop || 0);
  nextTick(() => {
    nextTick(() => {
      if (!container.value || props.noSelector) {
        return;
      }
      if (
        props.placement === 'center' &&
        container.value.offsetWidth > topElement.value.offsetWidth
      ) {
        left -=
          (container.value.offsetWidth - topElement.value.offsetWidth) / 2;
      }

      if (props.placement === 'left') {
        left -= container.value.offsetWidth - topElement.value.offsetWidth;
      }

      if (props.placement === 'right') {
        left += topElement.value.offsetWidth;
      }

      const topRect = topElement.value.getBoundingClientRect();
      const absoluteLeft = topRect.left + (left - baseLeft);
      const absoluteRight = absoluteLeft + container.value.offsetWidth;
      if (absoluteRight > window.innerWidth - 12) {
        left += window.innerWidth - 12 - absoluteRight;
      }
      container.value.style.transform = `translate(${left}px, ${0.5 + top}px)`;

      if (props.fixedWidth) {
        elem.style.maxWidth = dropdownView.value.offsetWidth + 'px';
      }
      elem.style.maxHeight = `calc(100vh - ${
        elem.getBoundingClientRect().top
      }px - 16px)`;

      const buttonWidth = openDropdownButtonRef.value?.clientWidth;
      if (buttonWidth) {
        elem.style.minWidth = `${buttonWidth}px`;
      }

      if (
        elem.getBoundingClientRect().height < 160 &&
        choicesView.value &&
        choicesView.value.offsetHeight < choicesView.value.scrollHeight
      ) {
        elem.style.maxHeight = `calc(${
          top - dropdownView.value.offsetHeight - 16
        }px)`;
        container.value.style.transform = `translate(${left}px, ${
          0.5 +
          top -
          dropdownView.value.offsetHeight -
          elem.getBoundingClientRect().height
        }px)`;
      }
    });
  });
};

const containerObserver = ref<ResizeObserver>(null);

watch(container, (elem: HTMLElement) => {
  if (elem) {
    if (containerObserver.value) {
      containerObserver.value.disconnect();
    }

    // Create new observer with debounced callback
    containerObserver.value = new ResizeObserver(() => {
      window.requestAnimationFrame(() => {
        autoplace(elem);
      });
    });
    containerObserver.value.observe(elem);
    parentScroll.value?.addEventListener('scroll', onScroll);
  } else {
    // Clean up
    if (containerObserver.value) {
      containerObserver.value.disconnect();
      containerObserver.value = null;
    }
    parentScroll.value?.removeEventListener('scroll', onScroll);
  }
});

watch(choicesView, (elem: HTMLElement) => {
  if (elem) {
    elem.addEventListener('scroll', onListScroll);
  }
});

const handleMousedown = (event) => {
  if (dropdownVisible.value) {
    if (
      !dropdownView.value?.contains(event.target) &&
      !container.value?.contains(event.target) &&
      event.target !== container.value
    ) {
      dropdownVisible.value = false;
      if (props.searchInside && searchInputInside.value) {
        searchInput.value =
          findOptionByValue(selectedOptions.value[0])?.wording || '';
      }
      emit('hide');
    }
  }
};

const handleResize = (event) => {
  if (container.value) {
    autoplace(container.value);
  }
};

const modelValueToSelectedOptions = (value) => {
  let ret = [];
  if (value !== undefined && value !== null) {
    if (props.multiple) {
      if (Array.isArray(value)) {
        ret = [...(value || [])];
      } else {
        ret = [value];
      }
    } else {
      ret = [value];
    }
  }
  return ret;
};

watch(
  () => props.modelValue,
  (v) => {
    if (props.searchInside && searchInputInside.value) {
      searchInput.value =
        findOptionByValue(selectedOptions.value[0])?.wording || '';
    }
  }
);

onMounted(() => {
  defaultLeft.value = topElement.value?.offsetLeft || 0;
  if (props.collisionCheckingEnabled) {
    console.warn(
      'DsNeoDropdown collisionCheckingEnabled it could impact performance'
    );
    checkCollisions();
  }
  selectedOptions.value = modelValueToSelectedOptions(props?.modelValue);
  watch(
    () => props.modelValue,
    (v) => {
      selectedOptions.value = modelValueToSelectedOptions(v);
    }
  );
  if (props.searchInside && searchInputInside.value) {
    searchInput.value =
      findOptionByValue(selectedOptions.value[0])?.wording || '';
  }
  window.document.addEventListener('mouseup', handleMousedown);
  window.addEventListener('resize', handleResize);

  if (props.noSelector && props.searchBox) {
    nextTick(() => {
      searchInputView.value.focus();
    });
  }
});

onBeforeUnmount(() => {
  window.document.removeEventListener('mouseup', handleMousedown);
  window.removeEventListener('resize', handleResize);
});

const toggleFoldGroup = (group) => {
  const folded = !foldedGroup.value[group];
  if (folded) {
    groupsWrapperViews.value[group].style.height =
      groupsViews.value[group].clientHeight + 'px';
  } else {
    delete groupsViews.value[group].style.height;
  }
  setTimeout(() => {
    foldedGroup.value[group] = folded;
  }, 1);
};

const groups = computed<any[]>(() => {
  const ret = filteredOptions.value.reduce((array, item) => {
    const group = item.group || 'other';
    array[group] = array[group] || [];
    array[group].push(item);
    return array;
  }, {});
  return Object.entries(ret) as any;
});

const grouping = computed(() => {
  return props.options.some((option) => !!option.group);
});

const filteredOptions = computed(() => {
  if (isInfiniteList.value) {
    return props.options;
  }

  const searchTerm = deburr(searchInput.value?.toLowerCase());

  const recurse = (parentList) => {
    const ret = [];
    if (props.dontFilter) {
      return parentList;
    }
    for (const option of parentList) {
      let subitems = null;
      const matches =
        option.alwaysVisible ||
        deburr(option?.wording?.toLowerCase()).includes(searchTerm) ||
        deburr(option?.toSearch?.toLowerCase()).includes(searchTerm);

      if (option.items) {
        subitems = recurse(option.items);
      }
      if (matches || subitems?.length > 0) {
        ret.push({
          ...option,
          items: subitems
        });
      }
    }
    return ret;
  };
  return recurse(props.options);
});

const checks = computed(() => {
  const recurseFlat = (parentList) => {
    const ret = [];
    for (const option of parentList) {
      ret.push(option);
      if (option.items?.length > 0) {
        ret.push(...recurseFlat(option.items));
      }
    }
    return ret;
  };

  if (props.hackFixCheckedCheckboxesThatDisplayUnchecked) {
    return Object.fromEntries(
      recurseFlat(props.options).map((option) => [
        option.value,
        !!selectedOptions.value?.includes(toRaw(option.value))
      ])
    );
  }

  return [
    Object.fromEntries(
      recurseFlat(props.options).map((option) => [
        option.value,
        !!selectedOptions.value?.includes(toRaw(option.value))
      ])
    )
  ];
});

const allValues = computed(() => {
  const recurse = (parentList) => {
    const ret = [];
    for (const option of parentList) {
      ret.push(option.value);
      if (option.items?.length > 0) {
        ret.push(...recurse(option.items));
      }
    }
    return ret;
  };

  return props.hasSelectAllButton ? recurse(filteredOptions.value) : [];
});

const isEverythingSelected = computed(() => {
  return selectedOptions.value.length === allValues.value.length;
});

const onSelectAll = () => {
  if (isEverythingSelected.value) {
    selectedOptions.value = [];
    emit('update:modelValue', []);
    return;
  }

  selectedOptions.value = allValues.value;
  emit('update:modelValue', [...selectedOptions.value]);
};

const toggleChecked = (option) => {
  if (props.isSelect) {
    dropdownVisible.value = false;
    emit('hide');
    emit('update:modelValue', option.value);
  } else if (props.multiple) {
    const idx = selectedOptions.value.findIndex(
      (v) => toRaw(v) === toRaw(option.value)
    );
    if (idx < 0) {
      selectedOptions.value.push(option.value);
    } else {
      selectedOptions.value.splice(idx, 1);
    }
    emit('update:modelValue', [...selectedOptions.value]);
  } else {
    if (searchInputInside.value) {
      searchInput.value = option.wording;
    }
    selectedOptions.value = [option.value];
    dropdownVisible.value = false;
    emit('hide');
    emit('update:modelValue', option.value);
  }
};

const findOptionByValue = (value) => {
  const recurse = (parentList) => {
    for (const option of parentList) {
      if (toRaw(option.value) === toRaw(value)) {
        return option;
      }
      if (option.items?.length > 0) {
        const ret = recurse(option.items);
        if (ret) {
          return ret;
        }
      }
    }
    return null;
  };
  return recurse(props.options);
};

const defaultLeft = ref(0);
</script>

<style scoped lang="scss">
.ds-neo-dropdown {
  & > .ds-neo-dropdown-view > .ds-neo-input {
    @include typo-body;

    cursor: pointer;
    user-select: none;

    display: flex;
    gap: 8px;
    align-items: center;

    min-height: 36px;
    max-height: 36px;
    padding: 8px 12px;

    background: #ffffff;
    border-radius: 8px;
    box-shadow:
      0 0 0 0 transparent,
      inset 0 0 0 1px $gray100;

    transition: box-shadow 0.1s ease-in-out;

    &:not(.disabled):not(:disabled) {
      &:hover {
        box-shadow:
          0 0 0 0 transparent,
          inset 0 0 0 1px $gray200;
      }
      &:focus-within,
      &:active,
      &:focus,
      &:active:focus,
      &.active {
        box-shadow:
          0 0 0 2px $blue150,
          inset 0 0 0 1px $blue500;
      }
    }

    & > input {
      width: 100%;
      height: 100%;
      border: none !important;
      outline: none !important;
      &::placeholder {
        color: $gray400;
      }
    }

    &.disabled {
      cursor: not-allowed;
      background-color: $gray50;

      & > input {
        cursor: not-allowed;
      }
    }

    &.small-pad {
      padding: 8px;
    }

    .icon {
      margin-left: auto;
    }

    .placeholder {
      @include text-nowrap;

      color: $gray400;
    }
  }
}

.ds-neo-dropdown-container {
  position: absolute;
  z-index: v-bind('$props.zIndex');
  top: 0;
  left: v-bind('defaultLeft');

  display: flex;
  flex-direction: column;
  gap: 4px;

  max-height: 370px;
  padding: 4px;

  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 4px 16px rgba(20, 23, 37, 0.08);

  .ds-neo-dropdown-choices {
    overflow: auto;
    .loader {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
    }
  }

  .ds-neo-dropdown-group-wrapper {
    overflow: hidden;
    transition: height 0.35s cubic-bezier(1, 0, 0, 1);

    &.folded {
      height: 0px !important;
    }
  }

  .ds-neo-dropdown-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .ds-neo-dropdown-group-title {
    cursor: pointer;

    position: sticky;
    z-index: 1;
    top: 0;

    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: start;

    padding: 4px 12px;

    background: $gray150;
    border-radius: 8px;

    &:not(.last) {
      margin-bottom: 4px;
    }

    .ds-neo-dropdown-group-title-content {
      display: flex;
      flex-direction: column;
      & > *:first-child {
        font-size: 10px;
        font-weight: 600;
        line-height: 14px;
        color: $gray700;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
      & > *:last-child {
        padding-top: 5px;

        font-size: 10px;
        color: $gray500;
        letter-spacing: 0.1px;
        white-space: wrap;
      }
      & > *:nth-child(2):empty {
        display: none;
      }
    }

    .ds-icon {
      margin-left: auto;
    }
  }
}

.secondary-wording {
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.1px;
}

.ds-dropdown-search-container {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;

  &:empty {
    display: none;
  }
}

.input-inside {
  flex-grow: 1;

  margin: 0;
  padding: 0;

  border: none !important;
  outline: none !important;
}

.no-result {
  @include typo-body;

  padding: 8px 12px;
  color: $gray700;
}
</style>
