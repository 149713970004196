<template>
  <div :data-test="dataTest">
    <Datepicker
      ref="refDatePicker"
      v-model="date"
      class="calendar"
      range
      auto-apply
      :multi-calendars="{ solo: true, static: true }"
      :enable-time-picker="false"
      :preset-dates="
        (!hidePresets && [
          ...presetDates,
          {
            label: 'Custom',
            value: date
          }
        ]) ||
        []
      "
      :max-date="computedMaxDate"
      :min-date="computedMinDate"
      inline
      :prevent-min-max-navigation="
        props.allowDatesAfterToday || !props.disablesDatesBeforeToday
          ? false
          : true
      "
      :month-change-on-scroll="false"
      hide-offset-dates
      :locale="$i18n.locale"
    >
      <template #preset-date-range-button="{ label, value, presetDate }">
        <span
          role="button"
          :tabindex="4"
          @click="presetDate(value)"
          @keyup.enter.prevent="presetDate(value)"
          @keyup.space.prevent="presetDate(value)"
        >
          {{ label }}
        </span>
      </template>
      <template #arrow-right>
        <DsIcon
          name="chevron-right"
          size="small"
          color="gray1000"
          style="width: 12px; height: 12px"
        />
      </template>
      <template #arrow-left>
        <DsIcon
          name="chevron-left"
          size="small"
          color="gray1000"
          style="width: 12px; height: 12px"
        />
      </template>
      <template #month-overlay-value="{ text, value }">
        <div class="month-overlay-value">
          <span>{{ value + 1 }}</span>
          <span>{{ text }}</span>
        </div>
      </template>
      <template #year-overlay-value="{ value }">
        <div class="year-overlay-value">
          <span>{{ value }}</span>
        </div>
      </template>
      <template #month="{ text }">
        {{ text }}
        <DsIcon
          name="chevron-down"
          size="small"
          color="gray400"
          style="margin-left: 2px"
        />
      </template>
      <template #year="{ value }">
        {{ value }}
        <DsIcon
          name="chevron-down"
          size="small"
          color="gray400"
          style="margin-left: 2px"
        />
      </template>
      <template #calendar-header="{ day }">
        {{ day.substring(0, 1) }}
      </template>
    </Datepicker>
  </div>
</template>

<script lang="ts" setup>
import '@vuepic/vue-datepicker/dist/main.css';

import Datepicker from '@vuepic/vue-datepicker';
import { startOfQuarter, startOfYear, subDays, subMonths } from 'date-fns';
import { computed, ref } from 'vue';

const refDatePicker = ref<typeof Datepicker | null>(null);
const defaultDateBegin = defineModel<Date>('defaultDateBegin');
const defaultDateEnd = defineModel<Date>('defaultDateEnd');

interface PresetDate {
  label: string;
  value: Date[];
}

const props = defineProps({
  allowDatesAfterToday: {
    type: Boolean,
    required: false,
    default: false
  },
  hidePresets: {
    type: Boolean,
    required: false,
    default: false
  },
  disablesDatesBeforeToday: {
    type: Boolean,
    required: false,
    default: false
  },
  minDate: {
    type: Date,
    default: null
  },
  maxDate: {
    type: Date,
    default: null
  },
  presetDates: {
    type: Array as () => PresetDate[],
    default: () => [
      {
        label: 'Last 7 days',
        value: [subDays(new Date(), 7), new Date()]
      },
      {
        label: 'Last 30 days',
        value: [subMonths(new Date(), 1), new Date()]
      },
      {
        label: 'Year to date',
        value: [startOfYear(new Date()), new Date()]
      },
      {
        label: 'Quarter to date',
        value: [startOfQuarter(new Date()), new Date()]
      }
    ]
  },
  dataTest: {
    type: String,
    required: false,
    default: undefined
  }
});

const emit = defineEmits<{
  (e: 'update:date'): void;
}>();

const date = computed({
  get: () => {
    if (defaultDateBegin.value && defaultDateEnd.value) {
      return [defaultDateBegin.value, defaultDateEnd.value];
    }
    return [];
  },
  set: (value) => {
    defaultDateBegin.value = value[0];
    defaultDateEnd.value = value[1];
    emit('update:date');
  }
});

const currentDate = ref(new Date());

// Optionally update currentDate periodically if minute/second precision is needed
// const updateInterval = 60000; // 1 minute
// onMounted(() => {
//   const timer = setInterval(() => {
//     currentDate.value = new Date();
//   }, updateInterval);
//   onUnmounted(() => clearInterval(timer));
// });

const computedMaxDate = computed(() => {
  if (!props.allowDatesAfterToday) return currentDate.value;
  return props.maxDate ?? null;
});

const computedMinDate = computed(() => {
  if (props.disablesDatesBeforeToday) return currentDate.value;
  return props.minDate ?? null;
});

// wip to apply focus on preset date for selected range
// onMounted(() => {
//   setTimeout(function () {
//     const test = document.getElementsByClassName('dp__btn dp--preset-range')
//     const test2 = test[0] as HTMLElement
//     test2.focus()
//     console.log('test', test2)
//   }, 1000)
// })
</script>

<style lang="scss" scoped>
.calendar {
  :deep(.dp__menu_inner) {
    padding: 0;
    border-top: 1px solid $gray100;
    .dp__instance_calendar {
      &:last-child {
        border-left: 1px solid $gray100;
      }
      > .dp__calendar {
        padding: 12px;
      }
      .dp--header-wrap {
        padding: 8px 12px;
        border-bottom: 1px solid $gray100;
        .dp__month_year_select {
          height: auto;
        }
        .dp__month_year_select:first-child {
          display: flex;
          align-items: right;
          justify-content: right;
        }
        .dp__month_year_select:last-child {
          display: flex;
          align-items: left;
          justify-content: left;
          margin-left: 8px;
        }
        .dp__month_year_wrap {
          .dp__month_year_select:hover {
            background-color: transparent;
          }
        }
      }

      .dp__calendar_header_separator {
        display: none;
      }

      .dp__calendar_row {
        > div:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          div {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }
        }
        > div:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          div {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }

      .dp__calendar_header_item {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 4px;

        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: $gray500;
        text-align: center;
        letter-spacing: -0.12px;
      }
    }
  }

  :deep(.dp__menu_content_wrapper) {
    display: flex;
    flex-direction: column;
    .dp--preset-dates {
      display: flex;
      align-items: center;
    }
  }

  :deep(.dp__calendar_header) {
    color: #83869a;
  }

  :deep(.dp--preset-dates) {
    display: flex;
    flex: 1 0 0;
    gap: 2px;
    align-items: center;

    margin: 8px 10px;
    padding: 2px;

    background: $gray100;
    border-inline-end: none;
    border-radius: 8px;
    button {
      display: flex;
      flex: 1 0 0;
      align-items: center;
      justify-content: center;

      padding: 2px 4px;

      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.12px;
    }
    button:active,
    button:focus {
      color: white !important;
      background: $blue500 !important;
      border-radius: 6px;
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.24);
    }
    button:hover {
      color: black;
      background: $gray150;
      border-radius: 6px;
    }
  }

  :deep(.dp__calendar_item) {
    padding-right: 2px;
    padding-left: 2px;
    &:first-child {
      padding-right: 2px;
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      padding-left: 2px;
    }

    &:has(div) {
      &:has(.dp__range_start) {
        background: linear-gradient(90deg, white 50%, $blue100 50%);
      }
      &:has(.dp__range_end) {
        background: linear-gradient(90deg, $blue100 50%, white 50%);
      }
      &:has(.dp__range_between) {
        background-color: $blue100;
      }
    }

    .dp__range_start {
      display: flex;
      flex-direction: column;
      gap: 10px;

      padding: 4px;

      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      line-height: 20px;
      color: white;
      text-align: center;
      letter-spacing: -0.14px;

      background: #425eff;
      border-radius: 8px;
    }
    .dp__range_end {
      display: flex;
      flex-direction: column;
      gap: 10px;

      padding: 4px;

      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      line-height: 20px;
      color: white;
      text-align: center;
      letter-spacing: -0.14px;

      background: #425eff;
      border-radius: 8px;
    }
    .dp__range_between {
      display: flex;
      gap: 4px;
      align-self: stretch;

      background: $blue100;
      border: 0;
      border-radius: 0;
    }

    .dp__cell_inner:hover {
      &:not(.dp__range_start):not(.dp__range_end) {
        background-color: $gray100;
      }
    }
  }
  :deep(.dp__theme_light) {
    --dp-cell-size: 28px;
    --dp-cell-padding: 4px;
    --dp-font-family: Inter, sans-serif;
    --dp-multi-calendars-spacing: 0;

    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.14px;

    border-color: transparent;
    border-radius: 12px;
  }
  :deep(.dp__overlay) {
    button {
      display: none !important;
    }
  }

  :deep(.dp__overlay_container) {
    &:has(.year-overlay-value) {
      display: flex;
      gap: 4px;
      padding: 12px;
    }
    .dp__overlay_cell:has(.month-overlay-value) {
      padding: 0;
      color: none;
      background: none;
      transition: none;
      span:first-child {
        height: 16px;

        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: $blue500;
        letter-spacing: -0.12px;
      }
      span:last-child {
        height: 16px;

        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: $gray1000;
        letter-spacing: -0.14px;
      }
      .month-overlay-value {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding: 4px;

        border-radius: 8px;
      }
      &:hover {
        .month-overlay-value {
          background: $gray100;
        }
      }
    }
    .dp__overlay_cell_active:has(.month-overlay-value) {
      padding: 0;
      color: none;
      background: none;
      transition: none;
      span:first-child {
        height: 16px;

        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: $blue200;
        letter-spacing: -0.12px;
      }
      span:last-child {
        height: 16px;

        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: white;
        letter-spacing: -0.14px;
      }
      .month-overlay-value {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding: 4px;

        background: $blue500;
        border-radius: 8px;
      }
    }

    .dp__overlay_cell:has(.year-overlay-value) {
      &:hover {
        background: none;
      }
    }
    .dp__overlay_row:has(.year-overlay-value) {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
      justify-content: center;
      .dp__overlay_col {
        width: 100%;
        padding: 4px;
        .dp__overlay_cell_pad {
          padding: 0;
        }
        .year-overlay-value {
          height: 20px;
        }
        &:hover {
          background-color: $gray100;
          border-radius: 8px;
        }
      }
      .dp__overlay_col:has(.dp__overlay_cell_active) {
        background-color: $blue500;
        border-radius: 8px;
        .dp__overlay_cell_active {
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          color: white;
          letter-spacing: -0.14px;

          background-color: $blue500;
        }
      }
    }
  }
}
:deep(.dp__inner_nav) {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  padding: 6px;

  background: $gray100;
  border-radius: 8px;
  svg {
    width: none;
    height: none;
  }
}
</style>
