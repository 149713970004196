<template>
  <div
    :class="{
      'ds-choices': true,
      'ds-choices--is-disabled': disabled
    }"
  >
    <label
      v-if="label || multiple"
      class="DS_field-label"
    >
      {{ label }}
      <span v-if="label && required"> * </span>
      <DsButton
        v-if="multiple && showSelectAll && !isAllSelected"
        class="btn-select-all"
        @click.stop.prevent="selectAll"
      >
        {{ $t('common.select_all') }}
      </DsButton>
      <DsButton
        v-else-if="multiple && showSelectAll && isAllSelected"
        class="btn-select-all"
        @click.stop.prevent="unselectAll"
      >
        {{ $t('common.unselect_all') }}
      </DsButton>
    </label>
    <div
      :key="refreshComponent"
      class="choices"
    >
      <component
        :is="multiple ? 'DsCheckbox' : 'DsRadio'"
        v-for="option of options"
        :key="option"
        class="ds-choice"
        :class="{ checked: isChecked(option) }"
        :checked="isChecked(option)"
        :disabled="option.disabled"
        :data-test="`${dataTest}-choice-${option.value}`"
        @update:modelValue="setChecked(toRaw(option), $event)"
      >
        <slot
          v-if="slots.item"
          name="item"
          v-bind="option"
        />
        <template v-else>
          {{ option?.wording || option?.value || option }}
        </template>
      </component>
    </div>
  </div>
</template>

<script setup lang="ts">
import { isEqual } from 'lodash';
import { computed, ref, toRaw, useSlots, watch } from 'vue';
const slots = useSlots();

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    multiple?: boolean;
    label?: string;
    required?: boolean;
    options?: any[];
    modelValue: any | any[];
    showSelectAll?: boolean;
    dataTest?: string;
  }>(),
  {
    disabled: false,
    multiple: false,
    label: null,
    required: false,
    options: [] as any,
    showSelectAll: true,
    dataTest: ''
  }
);

const refreshComponent = ref(0);

const emit = defineEmits<{ (e: 'update:modelValue', modelValue: any): void }>();

const selectedOptions = ref(
  props?.multiple
    ? props?.modelValue || []
    : ([props?.modelValue] as any) || null
);

const doShowSelectAll = computed(() => {
  return props.multiple && props.showSelectAll && props.options.length > 1;
});

watch(props, () => {
  const v = props?.multiple
    ? props?.modelValue || []
    : ([props?.modelValue] as any) || null;
  if (isEqual(v, selectedOptions.value) === false) {
    selectedOptions.value = v;
  }
  refreshComponent.value++;
});

const isChecked = (option) => {
  return (
    Array.isArray(selectedOptions.value) &&
    selectedOptions.value.includes(option.value)
  );
};

// const isChecked = computed((option) => {
//   return Array.isArray(selectedOptions.value) && selectedOptions.value.includes(option?.value)
// })

const setChecked = (option, checked: boolean) => {
  if (props.multiple) {
    if (!selectedOptions.value || !Array.isArray(selectedOptions.value)) {
      selectedOptions.value = [];
    }
    if (checked) {
      if (!selectedOptions.value.find((v) => v === option.value)) {
        selectedOptions.value.push(option.value);
      }
    } else {
      const idx = selectedOptions.value.findIndex((v) => v === option.value);
      if (idx >= 0) {
        selectedOptions.value.splice(idx, 1);
      }
    }
    emit('update:modelValue', [...selectedOptions.value]);
  } else {
    selectedOptions.value = [option.value];
    emit('update:modelValue', option.value);
  }
};

const isAllSelected = computed(() => {
  if (props.multiple) {
    return selectedOptions.value.length === props.options.length;
  }
  return false;
});

const selectAll = () => {
  if (props.multiple) {
    selectedOptions.value = [];
    for (const option of props.options) {
      selectedOptions.value.push(option.value);
    }
    emit('update:modelValue', [...selectedOptions.value]);
  }
};

const unselectAll = () => {
  if (props.multiple) {
    selectedOptions.value = [];
    emit('update:modelValue', []);
  }
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/design-system/field-label.scss';

.DS_field-label {
  display: flex;
  width: 100%;

  span {
    color: $red500;
  }
}

.btn-select-all {
  height: unset;
  margin-left: auto;
  padding: 0;

  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: $blue500;
  letter-spacing: -0.12px;
}

.choices {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;

  .ds-choice {
    flex: 1;

    padding: 8px 12px;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.14px;

    background: $gray50;
    border-radius: 12px;

    &.disabled {
      opacity: 0.5;
    }

    &.checked {
      background: $blue50;
    }
  }
}
</style>
