<template>
  <PfLabel
    :model-value="label"
    :required="required"
  />
  <div class="drag-and-drop-area-wrapper">
    <DsButton
      v-if="loading"
      color="white"
      readonly
      loading
      class="file-button file-button--loading"
    />
    <label
      v-else-if="!embedded || files?.length > 0"
      for="file-upload"
      @drop="handleDrop"
      @dragover.prevent
      @dragenter.prevent
      @click.stop.prevent
    >
      <template v-if="!embedded">
        <div
          v-if="!small"
          class="drag-and-drop-area"
        >
          <DsIcon
            name="upload"
            color="blue500"
            :scale="2"
          />
          <div class="area-description">
            <div class="drag-and-drop-text">
              <DsButton
                color="blue"
                @click="input?.click()"
              >
                {{ $t('dsFileUpload.choose_file' + (multiple ? 's' : '')) }}
              </DsButton>
              {{ $t('dsFileUpload.cta' + (multiple ? 's' : '')) }}
            </div>
            <div class="file-formats">
              {{ $t('dsFileUpload.availableFormats') }}
              <span
                v-for="(f, fI) in fileFormats"
                :key="fI"
              >
                {{ f }}
              </span>
              <div v-if="maxFileSizeInBytes">
                {{ $t('dsFileUpload.maxFileSize') }}
                {{ formatFileSize(maxFileSizeInBytes) }}
              </div>
              <div
                v-if="files?.length > 0 && showSelectedFiles"
                style="width: 100%"
              >
                <span v-if="!small">
                  {{ files?.length }}
                  {{ $t('dsFileUpload.selectedFiles', files.length) }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <DsButton
          v-else-if="multiple || !files || files.length === 0"
          icon="upload"
          color="white"
          class="file-button"
          style="cursor: pointer"
          @click="input?.click()"
        >
          {{ $t('dsFileUpload.click-to-choose-file') }}
        </DsButton>
      </template>
      <template v-if="files?.length && showSelectedFiles">
        <DsButton
          v-for="(f, fI) in files as any as File[]"
          :key="fI"
          right-icon="cross"
          custom-icon-color="gray700"
          color="white"
          class="file-button"
          @right-icon-action="removeFile(f)"
        >
          <DsIcon
            :name="mimeIcon(f.type)"
            color="gray700"
            style="margin-right: 8px"
          />
          <span class="filename">
            {{ f.name }}
          </span>
        </DsButton>
      </template>
    </label>
    <input
      id="file-upload"
      ref="input"
      :key="fileInputKey"
      style="display: none"
      type="file"
      :multiple="multiple"
      :accept="fileFormats.join(',')"
      @change="handleChange"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { mimeIcon } from '@/api/storage';
import { useToastStore } from '@/stores/toast';

import { formatFileSize } from '../../helpers/file';
import PfLabel from '../NewDesignSystem/PfLabel/PfLabel.vue';
import DsButton from './DsButton.vue';
import DsIcon from './DsIcon.vue';

const props = defineProps({
  loading: { type: Boolean, default: false },
  multiple: { type: Boolean, default: false },
  fileFormats: {
    type: Array,
    default: () => ['.jpg', '.jpeg', '.png', '.pdf']
  },
  maxFileSizeInBytes: { type: Number, default: 0 },
  showSelectedFiles: { type: Boolean, default: true },
  small: { type: Boolean, default: false },
  embedded: { type: Boolean, default: false },
  label: { type: String, default: '' },
  required: { type: Boolean, default: false }
});

const emit = defineEmits(['change', 'mounted']);

const toast = useToastStore();
const { t } = useI18n();

const files = ref<FileList>(null);
const input = ref<HTMLElement>(null);
const fileInputKey = ref(0);

const reset = () => {
  (input.value as any).value = '';
  files.value = null;
};

const addFileWithoutChangeEvent = (file: File) => {
  const dt = new DataTransfer();
  dt.items.add(file);
  files.value = dt.files;
};

const handleChange = (e) => {
  addFiles(e.target.files);
};

const handleDrop = (e) => {
  e.preventDefault();
  e.stopPropagation();

  const array = e.dataTransfer.files;

  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (props.maxFileSizeInBytes && element.size > props.maxFileSizeInBytes) {
      toast.showTextError(t('dsFileUpload.tooBig', { file: element.name }));
      return;
    }
    if (!props.multiple) {
      break;
    }
  }

  addFiles(e.dataTransfer.files);
};

const checkFormatFile = (fileName) => {
  return props.fileFormats.includes(
    `.${fileName.split('.').pop()?.toLowerCase()}`
  );
};

const addFiles = (newFiles) => {
  const dt = new DataTransfer();

  if (props.multiple) {
    for (let i = 0; i < files.value?.length; i++) {
      dt.items.add(files.value[i]);
    }
    for (let i = 0; i < newFiles.length; i++) {
      if (checkFormatFile(newFiles[i].name)) {
        dt.items.add(newFiles[i]);
        fileInputKey.value = fileInputKey.value + 1;
      } else {
        toast.showTextError(
          t('dsFileUpload.wrongFormat', { file: newFiles[i].name })
        );
      }
    }
  } else if (newFiles.length) {
    if (checkFormatFile(newFiles[0].name)) {
      dt.items.add(newFiles[0]);
    } else {
      toast.showTextError(
        t('dsFileUpload.wrongFormat', { file: newFiles[0].name })
      );
    }
  }
  files.value = dt.files;
  if (!files.value.length) return;
  emit('change', files.value);
};

const removeFile = (file) => {
  const dt = new DataTransfer();
  for (let i = 0; i < files.value?.length; i++) {
    if (files.value[i] !== file) {
      dt.items.add(files.value[i]);
    }
  }
  files.value = dt.files;
  emit('change', files.value);
};

onMounted(() => {
  emit('mounted');
});

defineExpose({
  reset,
  show: () => {
    input.value?.click();
  },
  addFiles: (files: FileList) => {
    addFiles(files);
  },
  addFileWithoutChangeEvent,
  files
});
</script>

<style lang="scss" scoped>
.drag-and-drop-area-wrapper {
  label {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
  }
}

.drag-and-drop-area {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 192px;
  padding: 10px;

  border: 2px dashed $gray200;
  border-radius: 12px;
}

.area-description {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;

  padding: 0px;
}

.drag-and-drop-text {
  display: flex;
  gap: 8px;
  align-items: center;
}

.file-formats {
  @include typo-small-body;

  color: $gray1000;
  text-align: center;
  opacity: 0.6;
}

.file-formats span {
  margin-right: 4px;
}

.file-button {
  cursor: default;
  justify-content: start;
  width: 100%;
  min-height: 36px;

  & > :deep(.slot-container) {
    justify-content: start;
    width: 100%;
  }

  & > :deep(*) {
    z-index: 1;
  }

  &.file-button--loading {
    justify-content: center;
  }

  .filename {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
