<template>
  <PfPopin
    id="main-ds-popin"
    v-model:visible="modalVisible"
    class="main-ds-popin"
    :title="title"
    :description="description"
    :cancelable="!hideCancel"
    :cancel-button-text="cancelTextButton"
    @close="handleCancelAction"
    @on-cancel="handleCancelAction"
  >
    <template #footer>
      <DsButton
        :color="validButtonColor"
        :loading="loadingSave"
        data-test="confirm-action-modal-button"
        @click="handleValidAction"
      >
        {{ validTextButton }}
      </DsButton>
    </template>
  </PfPopin>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import PfPopin from '../NewDesignSystem/PfPopin/PfPopin.vue';

const { t } = useI18n();
const loadingSave = ref(false);

const modalVisible = defineModel('visible', { type: Boolean, default: false });

const props = defineProps({
  title: { type: String, default: '' },
  description: { type: String, default: '' },
  validAction: { type: Function, default: undefined },
  cancelAction: { type: Function, default: undefined },
  validBtnText: { type: String, default: '' },
  cancelBtnText: { type: String, default: '' },
  autoClose: { type: Boolean, default: true },
  level: {
    type: String as PropType<'warning' | 'confirm' | 'danger'>,
    default: 'confirm'
  },
  hideCancel: { type: Boolean, default: false }
});

const handleValidAction = async () => {
  loadingSave.value = true;
  await props.validAction?.();
  if (props.autoClose) modalVisible.value = false;
  loadingSave.value = false;
};

const handleCancelAction = () => {
  if (props.autoClose) modalVisible.value = false;
  props.cancelAction?.();
};

const validTextButton = computed(() => {
  if (props.validBtnText) {
    return props.validBtnText;
  }
  return t('common.confirm');
});

const cancelTextButton = computed(() => {
  if (props.cancelBtnText) {
    return props.cancelBtnText;
  }
  return t('common.cancel');
});

const validButtonColor = computed(() => {
  if (props.level === 'warning') return 'orange';
  if (props.level === 'danger') return 'red';
  return 'blue';
});
</script>
