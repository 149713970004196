<template>
  <Teleport to="body">
    <div
      v-if="isVisible || alwaysRendered"
      v-show="isVisible"
      ref="popinOverlay"
      class="popin__overlay"
      tabindex="0"
      @click.self="$props.closable ? closePopin() : null"
      @keydown.esc="$props.closable || $props.cancelable ? closePopin() : null"
    >
      <div class="popin">
        <div
          v-if="slots.header || $props.title || description"
          class="popin__header"
          :class="{
            'popin__header--border-bottom': isBodyContentHitTop,
            'popin__header--extra-padding-bottom': !slots.body && !slots.footer
          }"
        >
          <div class="popin__title">
            <slot
              v-if="slots.header"
              name="header"
            ></slot>
            <PfText
              v-else-if="title"
              :typo="Typo.SmallTitle"
              color="gray1000"
            >
              {{ title }}
            </PfText>
            <DsButton
              v-if="closable"
              icon="cross"
              type="button"
              custom-icon-color="gray400"
              class="popin__close-btn"
              @click.stop="closePopin"
            />
          </div>
          <div
            v-if="description && !slots.header"
            class="popin__description"
          >
            <PfText
              :typo="Typo.Body"
              color="gray700"
            >
              {{ description }}
            </PfText>
          </div>
        </div>
        <div
          v-if="slots.body"
          ref="popinBodyContent"
          class="popin__body"
          :class="{
            'popin__body--scrollable': scrollable
          }"
        >
          <slot name="body" />
        </div>
        <div
          v-if="slots.footer || $props.cancelable"
          :class="{
            popin__footer: true,
            'popin__footer--border-top':
              !isBodyContentHitBottom && isContentScrollable,
            'popin__footer--no-cancel': !props.cancelable
          }"
        >
          <DsButton
            v-if="$props.cancelable"
            color="gray"
            @click="onCancel"
          >
            {{ cancelButtonText ?? t('common.back') }}
          </DsButton>
          <div
            v-if="slots.footer"
            class="popin__action-buttons"
          >
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import {
  computed,
  nextTick,
  onMounted,
  onUnmounted,
  onUpdated,
  ref,
  useSlots,
  watch
} from 'vue';
import { useI18n } from 'vue-i18n';

import PfText from '@/components/NewDesignSystem/PfText/PfText.vue';
import { Typo } from '@/constants/typography';

const slots = useSlots();
const { t } = useI18n();

const isVisible = defineModel('visible', {
  required: true,
  type: Boolean
});

const props = withDefaults(
  defineProps<{
    title?: string;
    description?: string;
    closable?: boolean;
    cancelable?: boolean;
    scrollable?: boolean;
    cancelButtonText?: string;
    width?: number;
    withOverflowAuto?: boolean;
    alwaysRendered?: boolean;
  }>(),
  {
    title: undefined,
    description: undefined,
    closable: false,
    cancelable: false,
    scrollable: false,
    cancelButtonText: undefined,
    width: 470,
    withOverflowAuto: false,
    alwaysRendered: false
  }
);

const emit = defineEmits<{
  (e: 'onClose'): void;
  (e: 'onCancel'): void;
}>();

const popinOverlay = ref<HTMLElement | null>(null);
const popinBodyContent = ref<HTMLElement | null>(null);
const isBodyContentHitTop = ref<boolean>(false);
const isBodyContentHitBottom = ref<boolean>(false);

const isContentScrollable = computed(() =>
  popinBodyContent.value
    ? popinBodyContent.value.scrollHeight > popinBodyContent.value.clientHeight
    : false
);

const computedWidth = computed(() => {
  return `${props.width}px`;
});

watch(
  () => isVisible.value,
  () => {
    if (isVisible.value) {
      nextTick(() => {
        popinOverlay.value?.focus();
        if (props.withOverflowAuto) {
          setTimeout(() => {
            const pop =
              document.querySelector<HTMLDivElement>('.popin__overlay');
            pop.style.overflow = 'auto';
          }, 100);
        }
      });
    }
  }
);

onMounted(() => {
  addScrollListener();
});

onUpdated(() => {
  addScrollListener();
});

onUnmounted(() => {
  removeScrollListener();
});

const computeBodyHittingTopBottom = () => {
  const scrollTop = Math.round(popinBodyContent.value?.scrollTop || 0);
  const scrollHeight = popinBodyContent.value?.scrollHeight || 0;
  const clientHeight = popinBodyContent.value?.clientHeight || 0;

  isBodyContentHitTop.value = scrollTop > 0;
  isBodyContentHitBottom.value = scrollHeight - scrollTop <= clientHeight;
};

const addScrollListener = () => {
  computeBodyHittingTopBottom();
  if (popinBodyContent.value) {
    popinBodyContent.value.addEventListener('scroll', () =>
      computeBodyHittingTopBottom()
    );
  }
};

const removeScrollListener = () => {
  if (popinBodyContent.value) {
    popinBodyContent.value.removeEventListener('scroll', () => {});
  }
};

const closePopin = () => {
  emit('onClose');
  isVisible.value = false;
};

const onCancel = () => {
  emit('onCancel');
  emit('onClose');
  isVisible.value = false;
};
</script>

<style lang="scss" scoped>
.popin__overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 32px 0;

  background: rgba(20, 23, 37, 0.3);
}

.popin {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: v-bind(computedWidth);
  height: fit-content;
  max-height: 100%;

  background: white;
  border-radius: 24px;
  box-shadow: 0px 16px 24px rgba(20, 23, 37, 0.16);

  .popin__header {
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;

    width: 100%;
    padding: 16px 24px 12px 24px;

    &.popin__header--border-bottom {
      box-shadow: inset 0 -1px 0 0 $gray100;
    }

    &.popin__header--extra-padding-bottom {
      padding: 16px 24px 16px 24px;
    }

    .popin__title {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    }

    .popin__close-btn {
      position: absolute;
      right: 24px;
      height: 28px;
      padding: 0;
    }
  }

  .popin__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;
    padding: 12px 24px;

    &.popin__body--scrollable {
      overflow-y: scroll;
    }
  }

  .popin__footer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: space-between;

    width: 100%;
    padding: 12px 24px 24px 24px;

    &.popin__footer--no-cancel {
      justify-content: flex-end;
    }

    &.popin__footer--border-top {
      box-shadow: inset 0 1px 0 0 $gray100;
    }

    .popin__action-buttons {
      display: flex;
      gap: 8px;
    }
  }
}
</style>
