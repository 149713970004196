import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';

import {
  getApprovalRequestUserScopes,
  GetApprovalRequestUserScopesOutput
} from '@/api/approval';
import { getUserRoles } from '@/api/bouncer';
import { getCurrentUserClient } from '@/api/mystix';
import {
  ClientModel,
  PaymentsRole,
  RetrieveRoles
} from '@/custom-types/client';
import { getSessionPayload } from '@/helpers/amplify';
import { useToastStore } from '@/stores/toast';

export const useClientStore = defineStore('client', () => {
  const client = ref<ClientModel>();
  const clientRoles = ref<RetrieveRoles>();
  const clientRequestScopes = ref<GetApprovalRequestUserScopesOutput>();

  const isLoadingClient = ref(true);

  const toast = useToastStore();

  const callGetCurrentUserClient = async () => {
    isLoadingClient.value = true;
    try {
      const session = await getSessionPayload();
      const [clientRet, rolesRet, scopesRet] = await Promise.all([
        getCurrentUserClient(session),
        getUserRoles(session.client_id),
        getApprovalRequestUserScopes(session.client_id)
      ]);
      clientRequestScopes.value = scopesRet;
      client.value = clientRet;
      clientRoles.value = (rolesRet?.user_roles || [])[0]?.roles;
      isLoadingClient.value = false;
    } catch (error) {
      toast.showError(error);
      isLoadingClient.value = false;
    }
  };

  function ready() {
    return new Promise((resolve) => {
      if (client.value && clientRoles.value) {
        resolve(true);
        return;
      }
      const unwatch = watch(isLoadingClient, (v) => {
        if (!v) {
          unwatch();
          resolve(true);
        }
      });
    });
  }

  const isRequestorOnly = computed(
    () =>
      (clientRoles.value?.payments_roles?.length === 1 &&
        clientRoles.value?.payments_roles?.includes(PaymentsRole.REQUESTOR)) ||
      false
  );

  const isAdminOrOwner = computed(
    () =>
      clientRoles.value?.settings_role === 'ADMIN' ||
      clientRoles.value?.settings_role === 'OWNER'
  );

  const isViewer = computed(
    () => clientRoles.value?.analytics_role === 'VIEWER'
  );

  const isViewerOrContributor = computed(
    () =>
      clientRoles.value?.analytics_role === 'VIEWER' ||
      clientRoles.value?.analytics_role === 'CONTRIBUTOR'
  );

  const isRequestor = computed(() =>
    clientRoles.value?.payments_roles?.includes(PaymentsRole.REQUESTOR)
  );

  const isApprover = computed(() =>
    clientRoles.value?.payments_roles?.includes(PaymentsRole.APPROVER)
  );

  const isController = computed(() =>
    clientRoles.value?.payments_roles?.includes(PaymentsRole.CONTROLLER)
  );

  const isPayer = computed(() =>
    clientRoles.value?.payments_roles?.includes(PaymentsRole.PAYER)
  );

  const isAllAccessPayer = computed(() =>
    clientRoles.value?.payments_roles?.includes(PaymentsRole.PAYER_ALL_ACCESS)
  );

  const isSuperApprover = computed(() =>
    clientRoles.value?.payments_roles?.includes(PaymentsRole.SUPER_APPROVER)
  );

  const fullName = computed(() => {
    if (!client.value) {
      return '';
    }
    return `${client.value.first_name} ${client.value.last_name}`;
  });

  return {
    client,
    clientRoles,
    clientRequestScopes,
    isLoadingClient,
    isRequestorOnly,
    callGetCurrentUserClient,
    ready,
    isAdminOrOwner,
    isViewer,
    isViewerOrContributor,
    isRequestor,
    isApprover,
    isSuperApprover,
    isController,
    isPayer,
    isAllAccessPayer,
    fullName
  };
});
