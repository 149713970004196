<template>
  <div>
    <div class="ds-text-field__label">
      <PfLabel
        :model-value="props.label"
        :required="props.required"
        :disabled="props.disabled"
        @click="openCalendar"
      />
    </div>
    <div
      id="id-date-field"
      class="date-field"
    >
      <button
        ref="dateFieldButtonRef"
        :disabled="props.disabled"
        type="button"
        class="date-field__button"
        :data-test="props.dataTest"
        @click="openCalendar"
      >
        <div class="date-field__button--left">
          <DsIcon
            v-if="!props.hideIcon"
            name="calendar"
            color="gray600"
          />
          <span v-if="modelValue">
            {{
              $d(modelValue, {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              })
            }}
          </span>
          <span
            v-else
            class="input__placeholder"
          >
            {{ props.placeholder }}
          </span>
        </div>
        <div style="display: flex; gap: 8px; align-items: center">
          <DsIcon
            v-if="modelValue && props.clearable && !props.disabled"
            :name="isCalendarOpen ? 'cross' : 'cross'"
            color="gray400"
            size="small"
            @click.stop="modelValue = undefined"
          />
          <DsIcon
            v-if="!props.hideChevron"
            :name="isCalendarOpen ? 'chevron-up' : 'chevron-down'"
            :color="isCalendarOpen ? 'blue500' : 'gray600'"
            size="small"
          />
        </div>
      </button>
      <div
        v-show="isCalendarOpen"
        ref="calendarRef"
        class="date-field__calendar"
        :style="{
          right: props.showRight ? '0px' : 'auto',
          left: props.showRight ? 'auto' : '0px'
        }"
      >
        <div class="date-field__calendar--single">
          <DsNewSingleCalendar
            v-model="modelValue"
            :allow-dates-after-today="!props.disableDatesAfterToday"
            :disables-dates-before-today="props.disableDatesBeforeToday"
            :disabled="props.disabled"
            :data-test="`${props.dataTest}-calendar`"
            @update:model-value="isCalendarOpen = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core';
import { onMounted, ref } from 'vue';

import DsNewSingleCalendar from '@/components/DesignSystem/DsNewSingleCalendar.vue';

import PfLabel from '../NewDesignSystem/PfLabel/PfLabel.vue';
import DsIcon from './DsIcon.vue';

const props = defineProps<{
  disabled?: boolean;
  label?: string;
  required?: boolean;
  defaultTo?: Date;
  placeholder?: string;
  disableDatesAfterToday?: boolean;
  disableDatesBeforeToday?: boolean;
  hideIcon?: boolean;
  hideChevron?: boolean;
  showRight?: boolean;
  clearable?: boolean;
  dataTest?: string;
}>();

const modelValue = defineModel<Date>();

const emit = defineEmits<{
  (e: 'onCloseCalendar'): void;
}>();

const dateFieldButtonRef = ref(null);
const isCalendarOpen = ref(false);

function openCalendar() {
  dateFieldButtonRef.value.focus();
  isCalendarOpen.value = true;
}

const calendarRef = ref<HTMLElement>();

onClickOutside(calendarRef, () => {
  if (!isCalendarOpen.value) {
    return;
  }

  isCalendarOpen.value = false;
  emit('onCloseCalendar');
});

onMounted(() => {
  if (props.defaultTo && !modelValue.value) {
    modelValue.value = props.defaultTo;
  }
});

defineExpose({
  openCalendar
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/design-system/field-label.scss';

.date-field {
  position: relative;

  &__button {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 36px;
    padding: 0 12px;

    background-color: white;
    border: 1px solid $gray100;
    border-radius: 12px;

    &--left {
      display: flex;
      gap: 8px;
      align-items: center;

      > span {
        white-space: nowrap;
      }
    }

    &:hover {
      border-color: $gray200;
    }

    &:focus {
      border-color: $blue500;
      outline: 2px solid $blue150;
    }

    &:disabled {
      cursor: not-allowed;
      color: $gray500;
      background-color: $gray50;
    }
  }

  &__calendar {
    position: absolute;
    z-index: 99;

    background-color: white;
    border-radius: 12px;
    box-shadow: 0 16px 24px 0 #14172529;
  }

  &__calendar--range {
    border-radius: 12px;
  }

  &__calendar--single {
    // padding: 24px;

    > div {
      width: 260px;
    }
  }
}

.label-asterisk {
  color: $red500;
}

.input__placeholder {
  @include typo-body;

  overflow: hidden;
  color: $gray400;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ds-text-field__label {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
}
</style>
