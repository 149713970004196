import { keyBy } from 'lodash-es';
import { defineStore } from 'pinia';
import { computed, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

import {
  getAccounts as getConnectorAccounts,
  getInstitutions
} from '@/api/connector';
import { getAccounts, getBalances, getPublicAccounts } from '@/api/fawkes';
import { getPaymentAccounts } from '@/api/payments';
import {
  ConnectorAccount,
  RetrieveInstitutionOutput
} from '@/api/schemas/connector';
import { RetrieveAccountOutput } from '@/api/schemas/fawkes';
import { useCallApi } from '@/composables/useCallApi';
import { useLazyLoad } from '@/composables/useLazyLoad';
import {
  AccountOptions,
  BalanceModel,
  PublicAccountModel
} from '@/custom-types/account';
import { formatAccountName } from '@/helpers/account';
import {
  BankOrdersCreateAccountSelectionFieldItem,
  joinProviderAccountsWithFawkesAccountAndBalanceAndInstitution
} from '@/helpers/bank-orders';

import { useToastStore } from '../toast';
import { useClientStore } from './client';

export const useAccountsStore = defineStore('accounts', () => {
  const toast = useToastStore();
  const { t } = useI18n();
  const route = useRoute();
  const router = useRouter();
  const clientStore = useClientStore();

  const accountsArray = ref<RetrieveAccountOutput[]>([]);
  const publicAccountsArray = ref<PublicAccountModel[]>([]);

  const connectorAccountsArray = ref<ConnectorAccount[]>([]);

  const institutionsArray = ref<RetrieveInstitutionOutput[]>([]);

  const balances = ref<BalanceModel[]>([]);

  const ebicsAccounts = ref<BankOrdersCreateAccountSelectionFieldItem[]>([]);
  const sftpAccounts = ref<BankOrdersCreateAccountSelectionFieldItem[]>([]);
  const paymentAccounts = ref<BankOrdersCreateAccountSelectionFieldItem[]>([]);
  const connectorAccounts = ref<BankOrdersCreateAccountSelectionFieldItem[]>(
    []
  );

  const allAccounts = ref<BankOrdersCreateAccountSelectionFieldItem[]>([]);
  const allAccountsLoading = ref(false);
  const allAccountsHasBeenCalled = ref(false);

  const {
    lazyLoad: lazyLoadAccounts,
    isPending: isAccountsLoading,
    lastCallTimestamp: lastAccountsCallTimestamp
  } = useLazyLoad([
    async () => {
      if (clientStore.isViewerOrContributor) {
        const response = await getAccounts();
        accountsArray.value = response.accounts;
      } else {
        accountsArray.value = [];
      }
    },
    async () => {
      const res = await getConnectorAccounts();
      connectorAccountsArray.value = res.accounts;
    }
  ]);

  const { lazyLoad: lazyLoadBalances, isPending: gettingBalances } =
    useLazyLoad([
      async () => {
        if (clientStore.isViewerOrContributor) {
          const response = await getBalances();
          balances.value = response.balances;
        } else {
          balances.value = [];
        }
      }
    ]);

  const statusOptions = computed(() => [
    {
      value: null,
      wording: t('accounts.statusOptions.all')
    },
    {
      value: 'EXPIRED',
      wording: t('accounts.statusOptions.expired')
    },
    {
      value: 'ACTIVE',
      wording: t('accounts.statusOptions.active')
    },
    {
      value: 'CLOSED',
      wording: t('accounts.statusOptions.closed')
    }
  ]);

  const filters = reactive({
    searchQuery: ref<string>(route.query.searchQuery?.toString() || ''),
    status: ref<string>(route.query.status?.toString() || null)
  });

  watch(filters, async () => {
    router.push({
      query: {
        ...route.query,
        ...filters
      }
    });
  });

  const warnAboutGetAccounts = () => {
    if (!lastAccountsCallTimestamp.value) {
      console.warn(
        "Accounts store methods are being used but callGetAccounts API hasn't been called yet"
      );
    }
  };

  const accountsIds = computed(() =>
    accountsArray.value.map((account) => account.id)
  );

  const accountsByIdDictionary = computed(
    (): { [id: number]: RetrieveAccountOutput } =>
      keyBy(accountsArray.value, 'id')
  );

  const callGetPublicAccounts = useCallApi(async () => {
    const response = await getPublicAccounts();
    publicAccountsArray.value = response.accounts;
  });

  const findAccountById = (accountId: number) => {
    warnAboutGetAccounts();

    return accountsByIdDictionary.value[accountId];
  };

  const accountsWithBalances = computed(() => {
    return accountsArray.value.map((account) => {
      const balance = balances.value.find(
        (balance) => balance.account_id === account.id
      );
      return {
        ...account,
        balance
      };
    });
  });

  const findAccountWithBalancesById = (accountId: number) => {
    return accountsWithBalances.value.find(
      (account) => account.id === accountId
    );
  };

  const callGetInstitutions = async () => {
    try {
      const response = await getInstitutions();
      institutionsArray.value = response.institutions;
    } catch (error) {
      console.error(error);
    }
  };

  const getAllAccounts = async () => {
    try {
      allAccountsHasBeenCalled.value = true;
      allAccountsLoading.value = true;

      let institutions = null;
      let accounts = null;
      let availableBalances = null;
      let providerAccounts = null;

      [institutions, accounts, availableBalances, providerAccounts] =
        await Promise.all([
          getInstitutions(),
          getAccounts(),
          getBalances(),
          getPaymentAccounts()
        ]);

      const list =
        joinProviderAccountsWithFawkesAccountAndBalanceAndInstitution(
          providerAccounts.accounts,
          accounts.accounts,
          availableBalances.balances,
          institutions.institutions
        );
      const all = [
        ...list.connectorAccounts,
        ...list.paymentAccounts,
        ...list.ebicsAccounts
      ];

      if (list.sftpAccounts?.length) {
        all.push(...list.sftpAccounts);
      }

      allAccounts.value = all;

      connectorAccounts.value = list.connectorAccounts;
      paymentAccounts.value = list.paymentAccounts;
      ebicsAccounts.value = list.ebicsAccounts;
      sftpAccounts.value = list.sftpAccounts;

      return all;
    } catch (error) {
      toast.showError(error, JSON.stringify(error.response.data));
    } finally {
      allAccountsLoading.value = false;
    }
  };

  const accountsOptions = computed<AccountOptions[]>(() =>
    accountsArray.value.map((account) => ({
      value: account.id,
      wording: formatAccountName(account),
      entityId: account.entity_id,
      groupId: account.group_id,
      institutionName: account.institution_name,
      iban: account.iban
    }))
  );

  return {
    lazyLoadAccounts,
    lazyLoadBalances,
    isAccountsLoading,
    allAccountsHasBeenCalled,
    accountsByIdDictionary,
    accountsArray,
    connectorAccountsArray,
    balances,
    accountsIds,
    gettingBalances,
    findAccountById,
    filters,
    statusOptions,
    accountsWithBalances,
    callGetPublicAccounts,
    publicAccountsArray,
    findAccountWithBalancesById,
    institutionsArray,
    callGetInstitutions,
    getAllAccounts,
    allAccounts,
    allAccountsLoading,
    ebicsAccounts,
    sftpAccounts,
    connectorAccounts,
    paymentAccounts,
    accountsOptions
  };
});
