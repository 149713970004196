import { Auth } from 'aws-amplify';

const isDebug = false; // import.meta.env.VITE_IS_MAIN !== 'true'

export async function getSessionPayload() {
  try {
    const session = await Auth.currentSession();

    if (isDebug) {
      console.log('Auth.currentSession', session);
    }

    const idToken = session.getIdToken();

    if (isDebug) {
      console.log('idToken', idToken);
    }

    return idToken.payload;
  } catch (error) {
    console.error(error);
    window.location.href = '/login?session_expired=true';
    throw error; // to avoid continuing with an empty output
  }
}

const TENANT_ID_KEY = 'tenantId';

export async function getTenantId(): Promise<string> {
  try {
    // Check if the tenant ID is saved in local storage
    const savedTenantId = localStorage.getItem(TENANT_ID_KEY);
    const queryTenantId = new URLSearchParams(window.location.search).get(
      'tenant_id'
    );
    const queryToken = new URLSearchParams(window.location.search).get('token');

    if (isDebug) {
      console.log('savedTenantId', savedTenantId);
    }

    if (savedTenantId) {
      // Tenant ID exists in local storage, return it
      return savedTenantId;
    }

    if (queryTenantId && queryToken) {
      return queryTenantId;
    }

    // If there's no tenant ID in local storage, fetch the session payload
    const sessionPayload = await getSessionPayload();

    if (isDebug) {
      console.log('sessionPayload', sessionPayload);
    }

    // Extract and return the tenant ID from the session payload
    const tenantId = sessionPayload.tenant_id;

    // Save the tenant ID in local storage for future use
    localStorage.setItem(TENANT_ID_KEY, tenantId);

    return tenantId;
  } catch (error) {
    console.error(error);
    // Handle errors as needed
  }
}

const TOKEN_KEY = 'jwtToken';
const TOKEN_ACCESS_KEY = 'jwtAccessToken';

export async function getUserJwtToken() {
  try {
    // Check if a token is saved in local storage and if it's not expired
    const savedToken = localStorage.getItem(TOKEN_KEY);

    if (isDebug) {
      console.log('savedToken', savedToken);
    }

    if (savedToken) {
      const tokenData = JSON.parse(savedToken);
      const expirationTime = new Date(tokenData.expirationTime);

      if (isDebug) {
        console.log('expirationTime', expirationTime);
      }

      if (expirationTime > new Date()) {
        // Token is still valid, return it
        return tokenData.token;
      }
    }
    // If there's no token or it's expired, fetch a new one
    const res = await Auth.currentSession();

    const idToken = res.getIdToken();
    const accessToken = res.getAccessToken();
    const jwt = idToken.getJwtToken();
    const jwtAccessToken = accessToken.getJwtToken();

    // Save the new token in local storage
    const expirationTime = new Date(idToken.payload.exp * 1000); // Convert expiration timestamp to milliseconds
    const newTokenData = {
      token: jwt,
      expirationTime: expirationTime.toISOString() // Store as ISO string
    };

    const newTokenAccessData = {
      token: jwtAccessToken
    };
    localStorage.setItem(TOKEN_KEY, JSON.stringify(newTokenData));
    localStorage.setItem(TOKEN_ACCESS_KEY, JSON.stringify(newTokenAccessData));

    if (isDebug) {
      console.log('newTokenData', newTokenData);
    }

    return jwt;
  } catch (error) {
    console.error(error);
    window.location.href = '/login?session_expired=true';
    throw error; // to avoid sending the API requests
  }
}
